import {
  TURN_OFF_LOADING_ACTION,
  TURN_ON_LOADING_ACTION,
  FILTER_CAMERA_MOSAIC_ACTION,
  PUT_CAMERA_MOSAIC_ACTION,
  SET_STYLES_ACTION,
  SET_PERMISSIONS_ACTION,
  SET_USER_CREATION_ROLES_ACTION,
  SET_SECONDS_ACTION,
  SET_PLAYING_ACTION,
  SET_REWIND_ACTION,
  SET_FAST_FORWARD_ACTION,
  SET_CAMERA_REFERENCE_ACTION,
  SET_SELECTED_ALERT_ACTION,
  SET_ALERTS_ACTION,
  SET_CAMERA_SELECTED_ACTION,
  SET_VIEW_LINK_MODAL_SELECTED_ACTION,
  SET_CUSTOMER_CAMERA_LIST_ACTION,
} from './actionTypes';
import { deploymentStreamSources, getCustomerStyles } from '../services/commonServices';

export const turnOffLoadingActionCreator = () => ({
  type: TURN_OFF_LOADING_ACTION,
});

export const turnOnLoadingActionCreator = () => ({
  type: TURN_ON_LOADING_ACTION,
});

export const filterCameraMosaicActionCreator = (data) => ({
  type: FILTER_CAMERA_MOSAIC_ACTION,
  data,
});

export const putCameraMosaicActionCreator = (data) => ({
  type: PUT_CAMERA_MOSAIC_ACTION,
  data,
});

export const fetchDeploymentCameras = (deployment_id) =>
  async function fetchSDeploymentCamerasThunk(dispatch) {
    await deploymentStreamSources(deployment_id, (response) => {
      dispatch(putCameraMosaicActionCreator({ deployment_id, cameraList: response.data }));
      return response.data;
    });
  };

export const setStylesActionCreator = (data) => ({
  type: SET_STYLES_ACTION,
  data,
});

export const setPermissionsActionCreator = (data) => ({
  type: SET_PERMISSIONS_ACTION,
  data,
});

export const setManageableUserRolesActionCreator = (data) => ({
  type: SET_USER_CREATION_ROLES_ACTION,
  data,
});

export const handleSetStyles = (dispatch) => async (username) => {
  await getCustomerStyles(username, (response) => {
    if (Object.keys(response.data).length > 0) {
      dispatch(setStylesActionCreator(response.data));
      localStorage.setItem('customerStyles', JSON.stringify(response.data));
    }
  });
};

export const setSecondsActionCreator = (data) => ({
  type: SET_SECONDS_ACTION,
  data,
});

export const setPlayingActionCreator = (data) => ({
  type: SET_PLAYING_ACTION,
  data,
});

export const setRewindActionCreator = (data) => ({
  type: SET_REWIND_ACTION,
  data,
});

export const setFastForwardActionCreator = (data) => ({
  type: SET_FAST_FORWARD_ACTION,
  data,
});

export const setCameraReferenceActionCreator = (data) => ({
  type: SET_CAMERA_REFERENCE_ACTION,
  data,
});

export const setSelectedAlertActionCreator = (data) => ({
  type: SET_SELECTED_ALERT_ACTION,
  data,
});

export const setAlertsActionCreator = (data) => ({
  type: SET_ALERTS_ACTION,
  data,
});

export const setCameraSelectedAction = (data) => ({
  type: SET_CAMERA_SELECTED_ACTION,
  data,
});

export const setViewLinkModalSelectedAction = (data) => ({
  type: SET_VIEW_LINK_MODAL_SELECTED_ACTION,
  data,
});

export const setCustomerCameraListAction = (data) => ({
  type: SET_CUSTOMER_CAMERA_LIST_ACTION,
  data,
});
