import { tenantPrimaryButton } from '../../utils/tenantStyles';

export const styles = (theme) => ({
  root: {
    zIndex: 0,
    padding: theme.spacing(0, 20),
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(6),
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cameraGrid: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  h4: {
    textAlign: 'left !important',
  },
  h5: {
    textAlign: 'left !important',
  },
  paper: {
    margin: 10,
    padding: theme.spacing(3),
    textAlign: 'left',
    flex: '0 1 auto',
  },
  camerasContainer: {
    marginBottom: theme.spacing(4),
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(4, 0),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
  },
  modalContainer: {
    border: 'none',
    width: '90%',
    height: '45vh',
    margin: 'auto',
    backgroundColor: 'white',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: theme.spacing(2),
    position: 'relative',
  },
  alertTable: {
    marginBottom: theme.spacing(12),
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
    padding: theme.spacing(3, 4),
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '600px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: theme.spacing(4),
      alignItems: 'center',
      padding: theme.spacing(1),
      maxWidth: '100%',
    },
  },
  textFieldBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(12),
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: theme.spacing(4),
      alignItems: 'center',
      width: '100%',
    },
  },
  textField: {
    border: '2px solid green',
    maxWidth: 200,
  },
  errorMessage: {
    color: 'red',
  },
  primaryButton: tenantPrimaryButton(theme),
});
