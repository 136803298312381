import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CamperasTemporaryCardListComponent from './components/TemporaryCamerasCardListComponent';
import { getCustomerStyles, getViewLinkService } from '../../services/commonServices';
import { useIntl } from 'react-intl';
import {
  AXEGA_CUSTOMER,
  BREADCRUMB_TEMPORARY_VIEWER,
  CSA_CUSTOMER,
  HTTP_CODE_401,
  HTTP_CODE_403,
  HTTP_CODE_404,
  MENU_AND_FOOTER_HEIGHT,
} from '../../utils/constants';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { styles } from './TemporaryViewerStyles';
import PageHeaderComponent from '../common/pageHeaderComponent/PageHeaderComponent';
import { TENANT_STYLES } from '../../utils/tenantStyles';
import {
  setStylesActionCreator,
  turnOffLoadingActionCreator,
  turnOnLoadingActionCreator,
} from '../../actions/commonActions';
import { useParams } from 'react-router-dom';
import ModalComponent from '../common/modal/ModalComponent';
import TextField from '@material-ui/core/TextField';
import ErrorComponent from '../shared/errorComponent/ErrorComponent';
import UELogo from '../../images/Logo_EU_ATEMPO.png';
import FullscreenRoundedIcon from '@material-ui/icons/FullscreenRounded';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import poster from '../../images/loadingV2.gif';
import TemporaryFullScreenMosaic from '../cameraMosaicComponent/cameraMosaicContainer/components/fullScreenMosaicComponent/TemporaryFullScreenMosaic';
import withAuthorization from '../shared/auth/withAuthorization';

const minHeight = window.innerHeight - MENU_AND_FOOTER_HEIGHT;
const useStyles = makeStyles((theme) => styles(theme, { minHeight }));

const TemporaryViewerComponent = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();

  const { viewer_id } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const urlData = urlParams.get('data');

  const [pin, setPin] = useState('');
  const [temporaryViewerData, setTemporaryViewerData] = useState(null);
  const [isLinkActive, setIsLinkActive] = useState(false);
  const [isPinRequired, setIsPinRequired] = useState(false);
  const [pinError, setPinError] = useState(false);

  const handleSetPin = (event) => {
    setPin(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      getViewLinkService(viewer_id, pin).then(validLinkCallback).catch(invalidLinkCallback);
    }
  };

  const getLink = () => {
    getViewLinkService(viewer_id, pin).then(validLinkCallback).catch(invalidLinkCallback);
  };

  const validLinkCallback = (response) => {
    setIsLinkActive(true);
    if (isPinRequired) setIsPinRequired(false);
    if (pinError) setPinError(false);
    dispatch(turnOffLoadingActionCreator());

    setTemporaryViewerData(response.data);

    getCustomerStyles(
      response.data.customer_id,
      (r) => getCustomerStylesCallback(r, response.data.customer_id),
      undefined,
      false,
    );

    setInterval(() => {
      getViewLinkService(viewer_id, pin)
        .then(() => {})
        .catch(invalidLinkCallback);
    }, 60000);
  };

  const invalidLinkCallback = (error) => {
    dispatch(turnOffLoadingActionCreator());

    if (!!temporaryViewerData) {
      setTemporaryViewerData(null);
    }

    if (error.response.status === HTTP_CODE_401 || error.response.status === HTTP_CODE_403) {
      setIsLinkActive(true);
      setIsPinRequired(true);
    }

    if (error.response.status === HTTP_CODE_403) {
      setPinError(true);
    }

    if (error.response.status === HTTP_CODE_404) {
      setIsLinkActive(false);
    }
  };

  const getCustomerStylesCallback = (response, customer) => {
    if (Object.keys(response?.data).length === 0) {
      dispatch(setStylesActionCreator(TENANT_STYLES));
    } else {
      const hasSecondaryLogo = customer === CSA_CUSTOMER || customer === AXEGA_CUSTOMER;

      const styles = {
        ...response.data,
        ...(hasSecondaryLogo && { secondaryLogo: UELogo }),
      };
      dispatch(setStylesActionCreator(styles));
    }
  };

  useEffect(() => {
    dispatch(turnOnLoadingActionCreator());

    getLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSendPin = () => {
    getLink();
  };

  const handleMosaic = useFullScreenHandle();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <PageHeaderComponent
          type={BREADCRUMB_TEMPORARY_VIEWER}
          titleOverride={temporaryViewerData?.description}
          date={temporaryViewerData?.expiration_date * 1000}
        />
        {temporaryViewerData && (
          <Button
            startIcon={<FullscreenRoundedIcon />}
            onClick={handleMosaic.enter}
            variant={'contained'}
            className={classes.primaryButton}
          >
            <Typography variant={'body2'}>{intl.formatMessage({ id: 'mosaic_fullscreen' })}</Typography>
          </Button>
        )}
      </div>
      {!!isLinkActive ? (
        <>
          {isPinRequired ? (
            <ModalComponent
              buttonAcceptText={intl.formatMessage({
                id: 'global_buttons_send',
              })}
              handleSave={handleSendPin}
              saveDisabled={pin.trim().length !== 4}
              body={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='h5' className={classes.subtitle}>
                    {intl.formatMessage({
                      id: 'view_link__pin_description',
                    })}
                  </Typography>
                  <div className={classes.textFieldContainer}>
                    <TextField
                      id='viewer-pin'
                      type='text'
                      required
                      value={pin}
                      label={intl.formatMessage({
                        id: 'pin',
                      })}
                      className={classes.textFieldBlock}
                      onChange={handleSetPin}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                  {pinError && (
                    <div>
                      <Typography variant='caption' className={classes.errorMessage}>
                        {intl.formatMessage({
                          id: 'view_link__pin_error',
                        })}
                      </Typography>
                    </div>
                  )}
                </div>
              }
            />
          ) : (
            <>
              {temporaryViewerData && (
                <>
                  {!handleMosaic.active && (
                    <CamperasTemporaryCardListComponent
                      classes={classes}
                      viewLinkCameras={temporaryViewerData?.view_links}
                      mosaicId={urlData}
                    />
                  )}
                  <FullScreen handle={handleMosaic}>
                    {handleMosaic.active && (
                      <TemporaryFullScreenMosaic
                        arrayOfCameras={temporaryViewerData?.view_links}
                        fullScreen={handleMosaic.active}
                        live
                        poster={poster}
                      />
                    )}
                  </FullScreen>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <ErrorComponent error='view_link__expired_description' />
      )}
    </div>
  );
};

export default withAuthorization()(TemporaryViewerComponent);
