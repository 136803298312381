import React from 'react';
import { FormattedMessage } from 'react-intl';

import headerLogo from '../../src/images/app_logo.png';

export const APP_VERSION = process.env.REACT_APP_VERSION;
export const DEFAULT_HTTP_TIMEOUT = 5000;
export const VIDEO_NETWORK_ERROR_TIMEOUT = 5000;
export const VIDEO_HLS_ERROR_TIMEOUT = 5000;
export const VIDEO_LEMBRA_ERROR_TIMEOUT = 5000;
export const STATS_TIMEOUT = 1000;
export const TIME_SECOND = 1000;
export const TIME_FF_REWIND = 200;

/**
 * HTTP CODES
 */
export const HTTP_CODE_401 = 401;
export const HTTP_CODE_403 = 403;
export const HTTP_CODE_404 = 404;
export const HTTP_CODE_422 = 422;
export const HTTP_CODE_409 = 409;

/**
 * HTTP ERROR CODE RESPONSE DATA
 */
export const LEMBRA001 = 'lembra-0001';

/**
 * KEYCLOAK
 */
export const KEYCLOAK_REALM = process.env.REACT_APP_KC_REALM;
export const KEYCLOAK_EUSKALTEL_URL = process.env.REACT_APP_KC_URL_EUSKALTEL;
export const KEYCLOAK_AXEGA_URL = process.env.REACT_APP_KC_URL_AXEGA;
export const KEYCLOACK_WEB_EUSKALTEL_CLIENTID = 'web-ekt';
export const KEYCLOACK_DEFAULT_CLIENTID = KEYCLOACK_WEB_EUSKALTEL_CLIENTID;
export const KEYCLOACK_DEFAULT_URL = KEYCLOAK_EUSKALTEL_URL;
export const KEYCLOACK_WEB_AXEGA_CLIENTID = 'web-axega';
export const TENANT_NAME_EUSKALTEL = 'euskaltel';
export const TENANT_NAME_AXEGA = 'axega';
export const TENANT_NAME_DEFAULT = TENANT_NAME_EUSKALTEL;

export const SUPPORTED_TENANTS = [TENANT_NAME_EUSKALTEL, TENANT_NAME_AXEGA];

export const KEYCLOAK_TENANT_CLIENTIDS = {
  [TENANT_NAME_EUSKALTEL]: KEYCLOACK_WEB_EUSKALTEL_CLIENTID,
  [TENANT_NAME_AXEGA]: KEYCLOACK_WEB_AXEGA_CLIENTID,
};

export const KEYCLOAK_TENANT_URL = {
  [TENANT_NAME_EUSKALTEL]: KEYCLOAK_EUSKALTEL_URL,
  [TENANT_NAME_AXEGA]: KEYCLOAK_EUSKALTEL_URL,
};

/**
 * BACKEND API
 */
export const TIIVII_API_HOST = process.env.REACT_APP_TIIVII_API_HOST;
export const TIIVII_API_BASE_URL = process.env.REACT_APP_TIIVII_API_BASE_URL;
export const SUPERVISION_API_BASE_URL = process.env.REACT_APP_SUPERVISION_TIIVII_API_BASE_URL;

/**
 * TIIVII ENDPOINTS
 */
export const TIIVII_API_DEPLOYMENT_LIST_BY_USER = `${TIIVII_API_BASE_URL}users/:username/service-contracts?service_codename=TIIVII-VIDEOSUPERVISION`;
export const TIIVII_API_SERVICE_CONTRACTS = `${TIIVII_API_BASE_URL}service-contracts?deployment_id=:deployment_id`;
export const TIIVII_API_DEPLOYMENT_LIST_BY_CUSTOMER = `${TIIVII_API_BASE_URL}customers/:customer_id/contracts?service_codename=TIIVII-VIDEOSUPERVISION`;
export const TIIVII_API_CUSTOMER_LIST = `${TIIVII_API_BASE_URL}users/:username/customers?service_codename=TIIVII-VIDEOSUPERVISION`;
export const TIIVII_API_DEPLOYMENT = `${TIIVII_API_BASE_URL}deployments/:deployment_id`;
export const TIIVII_API_DEPLOYMENT_STREAM_SOURCES = `${TIIVII_API_BASE_URL}deployments/:deployment_id/streamsources`;
export const TIIVII_API_THEME = `${TIIVII_API_BASE_URL}customers/:customer/theme`;
export const TIIVII_API_USER_INFO = `${TIIVII_API_BASE_URL}users/:username`;
export const TIIVII_API_USERS_BY_CUSTOMER = `${TIIVII_API_BASE_URL}customers/:customer_id/users`;
export const TIIVII_API_USER = `${TIIVII_API_BASE_URL}customers/:customer_id/users/:user_id`;
export const TIIVII_API_NEW_USER = `${TIIVII_API_BASE_URL}users`;
export const TIIVII_SUPERVISION_API_POST_OVERLAY = `${TIIVII_API_BASE_URL}streamsources/:streamsource_id/operations`;
export const TIIVII_SUPERVISION_API_GET_USER_ROLES = `${TIIVII_API_BASE_URL}user-roles/:role_name`;

/**
 * SUPERVISION ENDPOINTS
 */
export const TIIVII_API_DEPLOYMENT_STREAM_SOURCE = `${SUPERVISION_API_BASE_URL}deployments/:deployment_id/streamsources/:streamsource_id`;
export const TIIVII_API_LIVE_URL = `${SUPERVISION_API_BASE_URL}deployments/:deployment_id/streamsources/:streamsource_id/live_url`;
export const TIIVII_API_REC_URL = `${SUPERVISION_API_BASE_URL}deployments/:deployment_id/streamsources/:streamsource_id/rec_url`;
export const TIIVII_API_LEMBRA_SOURCES = `${SUPERVISION_API_BASE_URL}service-contracts/:service_contract_id/streamsources/:streamsource_id/lembra-sources`;
export const TIIVII_API_REC_POLICY_UPDATE = `${SUPERVISION_API_BASE_URL}service-contracts/:service_contract_id/streamsources/:streamsource_id/rec_policy`;
export const TIIVII_API_REC_POLICY = `${SUPERVISION_API_BASE_URL}deployments/:deployment_id/rec_policy`;
export const TIIVII_API_MOSAIC_REC_POLICY = `${SUPERVISION_API_BASE_URL}mosaics/:mosaic_id/rec_policy`;
export const TIIVII_API_DESACTIVATE_CAMERA = `${SUPERVISION_API_BASE_URL}deployments/:deployment_id/streamsources/:streamsource_id/deactivate`;
export const TIIVII_API_ACTIVATE_CAMERA = `${SUPERVISION_API_BASE_URL}deployments/:deployment_id/streamsources/:streamsource_id/activate`;
export const TIIVII_API_CREATE_CLIP = `${SUPERVISION_API_BASE_URL}deployments/:deployment_id/streamsources/:streamsource_id/clips`;
export const TIIVII_API_DOWNLOAD_CLIP = `${SUPERVISION_API_BASE_URL}deployments/:deployment_id/streamsources/:streamsource_id/clips/:clip_id/download_url`;
export const TIIVII_API_ALARMS = `${SUPERVISION_API_BASE_URL}streamsource_alerts`;
export const TIIVII_API_SUPERVISION_THEME = `${SUPERVISION_API_BASE_URL}public/customers/:customer/theme`;
export const TIIVII_API_CREATE_TEMPORARY_MOSAIC = `${SUPERVISION_API_BASE_URL}temporary-mosaics`;
export const TIIVII_API_GET_TEMPORARY_MOSAIC = `${SUPERVISION_API_BASE_URL}public/temporary-mosaics/:mosaic_id`;
export const TIIVII_API_TEMPORARY_MOSAIC = `${SUPERVISION_API_BASE_URL}temporary-mosaics/:mosaic_id`;
export const TIIVII_API_TEMPORARY_MOSAIC_EXPIRE = `${SUPERVISION_API_BASE_URL}temporary-mosaics/:mosaic_id/revoke`;
export const TIIVII_API_TEMPORARY_MOSAIC_LIST = `${SUPERVISION_API_BASE_URL}temporary-mosaics?customer_id=:customer_id`;
export const TIIVII_SUPERVISION_API_KEEP_ALIVE_BY_STREAMSOURCE = `${SUPERVISION_API_BASE_URL}deployments/:deployment_id/streamsources/:streamsource_id/keep_alive`;
export const TIIVII_SUPERVISION_API_KEEP_ALIVE_BY_DEPLOYMENT = `${SUPERVISION_API_BASE_URL}deployments/:deployment_id/keep_alive`;
export const TIIVII_SUPERVISION_API_KEEP_ALIVE_BY_MOSAIC = `${SUPERVISION_API_BASE_URL}mosaics/:mosaic_id/keep_alive`;
export const TIIVII_SUPERVISION_API_GET_MOSAICS = `${SUPERVISION_API_BASE_URL}mosaics`;
export const TIIVII_SUPERVISION_API_GET_FILTERED_MOSAIC = `${SUPERVISION_API_BASE_URL}mosaics/:mosaic_id`;
export const TIIVII_SUPERVISION_API_GET_UNIQUE_MOSAIC = `${SUPERVISION_API_BASE_URL}mosaics/:mosaic_id/extended`;
export const TIIVII_SUPERVISION_API_POST_FILTERED_MOSAIC = `${SUPERVISION_API_BASE_URL}mosaics/:mosaic_id/streamsources`;
export const TIIVII_SUPERVISION_API_GET_SERVICE_CONTRACT = `${SUPERVISION_API_BASE_URL}supervision-contracts/:service_contract_id`;

/**
 * PTZ API
 */
export const PTZ_CONTROLLER_BASE_URL = process.env.REACT_APP_TIIVII_PTZ_CONTROLLER_API_BASE_URL;
export const PTZ_MJPEG_URL = `${PTZ_CONTROLLER_BASE_URL}/streamsources/:streamsource_id/url_mjpeg`;
export const PTZ_CONTROLLER_MOVE = `${PTZ_CONTROLLER_BASE_URL}/streamsources/:streamsource_id/move`;
export const PTZ_CONTROLLER_ZOOM = `${PTZ_CONTROLLER_BASE_URL}/streamsources/:streamsource_id/zoom`;
export const PTZ_CONTROLLER_CENTER = `${PTZ_CONTROLLER_BASE_URL}/streamsources/:streamsource_id/center`;
export const PTZ_CONTROLLER_ABSOLUTE = `${PTZ_CONTROLLER_BASE_URL}/streamsources/:streamsource_id/absolute`;
export const PTZ_CONTROLLER_GO_TO_PRESET = `${PTZ_CONTROLLER_BASE_URL}/streamsources/:streamsource_id/goto_preset`;
export const PTZ_CONTROLLER_CHECK_CONNECTION = `${PTZ_CONTROLLER_BASE_URL}/streamsources/:streamsource_id/test`;
export const PTZ_CONTROLLER_PRESETS = `${PTZ_CONTROLLER_BASE_URL}/streamsources/:streamsource_id/presets`;
export const PTZ_CONTROLLER_CURRENT_PRESET = `${PTZ_CONTROLLER_BASE_URL}/streamsources/:streamsource_id/presets/current`;
export const PTZ_CONTROLLER_SET_PRESET = `${PTZ_CONTROLLER_BASE_URL}/streamsources/:streamsource_id/presets/:preset`;
export const PTZ_CONTROLLER_DELETE_PRESET = `${PTZ_CONTROLLER_BASE_URL}/streamsources/:streamsource_id/presets/:preset`;
export const PTZ_CONTROLLER_UPDATE_PRESET = `${PTZ_CONTROLLER_BASE_URL}/streamsources/:streamsource_id/presets/:preset`;

/**
 * ROLES
 */
export const ROLE_STARTS_WITH = 'TIIVII';
export const ROLE_TIIVII_SUPER_USER = 'TIIVII_SUPER_USER';
export const ROLE_TIIVII_PARTNER_USER = 'TIIVII_PARTNER_USER';
export const ROLE_TTIIVII_TECHNICAL_USER = 'TIIVII_TECHNICAL_USER';
export const ROLE_TIIVII_CLIENT_USER = 'TIIVII_CLIENT_USER';
export const ROLE_TIIVII_CLIENT_ADMIN_USER = 'TIIVII_CLIENT_ADMIN_USER';
export const ROLE_TIIVII_ADMIN_CLIENT_USER = 'TIIVII_ADMIN_CLIENT_USER';
export const ROLE_TIIVII_LIVE_VIEWER_USER = 'TIIVII_LIVE_VIEWER_USER';

export const ROLES_ADMIN = [ROLE_TIIVII_SUPER_USER, ROLE_TIIVII_PARTNER_USER];
export const ROLES_MANAGER = [ROLE_TIIVII_SUPER_USER, ROLE_TIIVII_PARTNER_USER, ROLE_TIIVII_CLIENT_ADMIN_USER];

/**
 * PERMISSIONS
 */
export const PERMISSION_CUSTOMER_VIEW = 'customer_view';
export const PERMISSION_DEPLOYMENT_VIEW = 'deployment_view';
export const PERMISSION_RECORDING_VIEW = 'recording_view';
export const PERMISSION_USER_VIEW = 'user_view';
export const PERMISSION_USER_MANAGE = 'user_manage';
export const LOADING_PERMISSIONS = 'loading_permissions';

/**
 * NOTIFICATION SEVERITY
 */
export const SEVERITY_SUCCESS = 'success';
export const SEVERITY_INFO = 'info';
export const SEVERITY_WARNING = 'warning';
export const SEVERITY_ERROR = 'error';

/**
 * I18N
 */
export const LANGUAGES = {
  ES: 'es',
  EN: 'en',
  GL: 'gl',
};

export const LANGUAGES_ARRAY = [LANGUAGES.ES, LANGUAGES.EN, LANGUAGES.GL];

/**
 * kEEP_ALIVE
 */

export const KEEP_ALIVE_TIMEOUT = 6000;

/**
 * URL
 */
export const STATE_URL_PENDING = 'pending';
export const STATE_URL_AVAILABLE = 'available';
export const STATE_URL_ERROR = 'error';

/**
 * DOWNLOAD
 */
export const DOWNLOAD_TIME_LIMIT_MINUTES = 60;
export const DOWNLOAD_TIME_LIMIT_MILLISECONDS = 3600000;
export const DOWNLOAD_MINIMUM_SLIDER_RANGE = 60000;
export const DOWNLOAD_CLIP_FORMAT = 'mp4';

/**
 * BREADCRUMBS
 */
export const BREADCRUMB_CUSTOMER_lIST = 'customerList';
export const BREADCRUMB_DEPLOYMENTS_DETAIL = 'deploymentDetails';
export const BREADCRUMB_MOSAIC = 'mosaic';
export const BREADCRUMB_MOSAIC_AND_CAMERA = 'mosaicAndCamera';
export const BREADCRUMB_CUSTOMER_MOSAIC = 'customerMosaic';
export const BREADCRUMB_SERVICE_DEPLOYMENT_LIST = 'deploymentList';
export const BREADCRUMB_PROFILE = 'profile';
export const BREADCRUMB_MANAGEMENT = 'management';
export const BREADCRUMB_VIEW_LINKS = 'viewLinks';
export const BREADCRUMB_TEMPORARY_VIEWER = 'temporaryViewer';

/**
 * ROUTE NAMES
 */
export const ROUTE_NAME_CUSTOMER_LIST = 'customers';
export const ROUTE_NAME_DEPLOYMENT_LIST = 'deployments';
export const ROUTE_NAME_MOSAIC = 'mosaic';
export const ROUTE_NAME_VIEWER = 'viewer';

/**
 * STYLES
 */
export const GRAY = '#707070';
export const DISABLED = '#c9c9c9';

export const DEFAULT_STYLE = {
  ui_theme: {
    primary_color: {
      main: '#FF5800',
    },
  },
  header_data: {
    logo: {
      alt: 'Logo',
      type: 'import',
      link: 'https://www.euskaltel.com/',
      src: headerLogo,
    },
  },
};

export const BLANK_STYLE = {
  isDefault: true,
  ui_theme: {
    primary_color: {
      main: GRAY,
    },
  },
  header_data: '',
};

/**
 * MOSAIC
 */
export const CAMERAS_PER_PAGE = 6;
export const FF_REWIND_VALUE_MILLISECONDS = 3000;
export const FF_REWIND_VALUE_SECONDS = FF_REWIND_VALUE_MILLISECONDS / 1000;

/**
 * CAMERA STATUS
 */
export const CAMERA_STATUS_OK = 'ok';
export const CAMERA_STATUS_ACTIVE = 'active';

/**
 * LEMBRA
 */
export const LEMBRA_SOURCE_STATE_RUNNING = 'running';

/**
 * LANGUAGES
 */
export const I18N_CONFIG = {
  es: {
    label: 'intEs',
    title: 'es',
  },
  en: {
    label: 'intEn',
    title: 'en',
  },
  gl: {
    label: 'intGl',
    title: 'gl',
  },
};

export const DATES_FIELDS = [
  'first_seen',
  'last_checked',
  'last_status_ok',
  'last_thumbnail_timestamp',
  'last_checked',
];

/**
 * LOCAL STORAGE
 */
export const LOCAL_STORAGE__LOCALE = 'locale_SV';
export const LOCAL_STORAGE__TENANT = 'tenant';
export const LOCAL_STORAGE__CUSTOMER_ID = 'customer';
export const LOCAL_STORAGE__CUSTOMER_STYLES = 'customer_styles';
export const LOCAL_STORAGE__FILTERED_CAMERA_LIST = 'filtered_camera_list';
export const LOCAL_STORAGE__FILTERED_CUSTOMER_CAMERA_LIST = 'filtered_customr_camera_list';

/**
 * ALERTS
 */
export const ALERT_TIME_RANGE_VALUE = 180; //Three minutes
export const ALERT_TIME_RANGE_VALUE_MILLISECONDS = 180000; //Three minutes
export const ALERT_TABLE_ITEMS_PER_PAGE = 5;
export const ALERT_TIME_UPDATE = 90000; //1:30 minutes

/**
 * VIDEO CONTROLS
 */
export const REWIND_CONTROL_LABEL = 'rewind';
export const FF_CONTROL_LABEL = 'ff';
export const TIMELINE_VALUE_CHANGE = 10;

/**
 * PAGE STYLES
 */
export const MENU_AND_FOOTER_HEIGHT = 160;
export const MIN_HEIGHT = window.innerHeight - MENU_AND_FOOTER_HEIGHT;

/**
 * USER TIMEOUTS
 */
export const MESSAGE_TIMEOUT = 2000;
export const GET_URL_INTERVAL = 2000;
export const GET_URL_INTERVAL_NUMBER_OF_TRIES = 10;

/**
 * VIDEO
 */
export const VIDEO_WIDTH_RATIO = 16;
export const VIDEO_HEIGHT_RATIO = 9;
export const VIDEO_RATIO_PERCENTAGE = (VIDEO_HEIGHT_RATIO * 100) / VIDEO_WIDTH_RATIO / 100;
export const INITIAL_SECONDS = 0.0;

/**
 * PTZ
 */
export const CAMERA_PROXY_BASE_URL = 'https://proxyptz.deployments.tiivii.com/:camera_id';
export const AXIS_PTZ_AVAILABLE_ERROR = '^Error:';

/**
 * NETWORK VALUES
 */
export const MAX_PENDING_REQUESTS = 15;
export const NETWORK_DEFAULT_LATENCY = 1;
export const NETWORK_LATENCY_OPTIONS = [
  {
    id: 'fast',
    name: <FormattedMessage id='network_latency_options_fast' />,
    timeout: 240,
    wait: 60,
    max_wait: 180,
  },
  {
    id: 'average',
    name: <FormattedMessage id='network_latency_options_average' />,
    timeout: 500,
    wait: 100,
    max_wait: 300,
  },
  {
    id: 'slow',
    name: <FormattedMessage id='network_latency_options_slow' />,
    timeout: 1000,
    wait: 200,
    max_wait: 600,
  },
  {
    id: 'slower',
    name: <FormattedMessage id='network_latency_options_slower' />,
    timeout: 2000,
    wait: 500,
    max_wait: 1500,
  },
];

export const DEFAULT_REC_POLICY = 2592000;

export const OS_MAC = 'Mac OS';
export const OS_IOS = 'iOS';
export const OS_WIN = 'Windows';
export const OS_ANDROID = 'Android';
export const OS_LINUX = 'Linux';

export const BROWSER_FIREFOX = 'firefox';

export const SVG_URI = 'http://www.w3.org/2000/svg';

export const CSA_CUSTOMER = '65e0723ca1d7e66666a03ab5';
export const AXEGA_CUSTOMER = '629db118a126dd67713e98ac';

export const KEY_BACKSPACE = 'Backspace';
export const KEY_DELETE = 'Delete';
export const KEY_ARROW_LEFT = 'ArrowLeft';
export const KEY_ARROW_RIGHT = 'ArrowRight';
export const KEY_TAB = 'Tab';

export const ALLOWED_TYPING_KEYS = [KEY_BACKSPACE, KEY_DELETE, KEY_ARROW_LEFT, KEY_ARROW_RIGHT, KEY_TAB];

/**
 * OVERLAY VALUES
 */
export const OVERLAY_TOP_LEFT = 'topLeft';
export const OVERLAY_TOP_RIGHT = 'topRight';
export const OVERLAY_BOTTOM_LEFT = 'bottomLeft';
export const OVERLAY_BOTTOM_RIGHT = 'bottomRight';

export const AVAILABLE_OVERLAY_VALUES = [
  OVERLAY_TOP_LEFT,
  OVERLAY_TOP_RIGHT,
  OVERLAY_BOTTOM_LEFT,
  OVERLAY_BOTTOM_RIGHT,
];

export const DEFAULT_OVERLAY_VALUE = OVERLAY_BOTTOM_LEFT;
