import { makeStyles } from '@material-ui/core/styles';
import { GRAY } from '../../../utils/constants';
import { tenantPrimaryButton } from '../../../utils/tenantStyles';

export const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  root: {
    textAlign: 'left',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      margin: '34px 0',
    },
  },
  titlePage: {
    fontWeight: 'bold',
    fontSize: '1.7rem',
    color: GRAY,
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
    },
  },
  subtitle: {
    color: '#5c5c5c',
  },
  primaryButton: tenantPrimaryButton(theme),
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
}));
