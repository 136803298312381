import { tenantSecondaryButton } from '../../../utils/tenantStyles';

export const styles = (theme) => ({
  linkForm: {
    marginBottom: theme.spacing(2),
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '2rem',
  },
  viewLinkModalContents: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(4),
  },
  viewLinkModalOption: {},
  radioGroup: {
    flexDirection: 'row',
    margin: '0px',
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  textAreaContainer: {
    width: '80%',
  },
  menuItem: {
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white.main,
    },
    '&.MuiListItem-root:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white.main,
    },
  },
  checkbox: {
    '&.Mui-checked': {
      color: theme.palette.white.main,
    },
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 2, 1, 2),
  },
  disabled: {
    backgroundColor: theme.palette.disabled.light + '!important',
    border: 0 + '!important',
    color: theme.palette.disabled.main + '!important',
  },
  secondaryButton: tenantSecondaryButton(theme),
  select: {
    width: '100%',
  },
  datePinContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  viewLink: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 450,
    [theme.breakpoints.down('md')]: {
      minWidth: 200,
    },
  },
});
