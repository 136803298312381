import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { SEVERITY_ERROR, SEVERITY_INFO } from '../../../utils/constants';
import { isString } from '../../../utils/utils';
import { injectIntl } from 'react-intl';

const styles = (theme) => ({
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
});

let setMessageFn;
let setMessageInt;

class NotificationSnack extends React.Component {
  state = {
    open: false,
    message: '',
    severity: SEVERITY_INFO,
  };
  componentDidMount() {
    setMessageFn = this.setMessage;
    setMessageInt = this.setMessageInt;
  }

  handleClose = () => {
    this.setState({
      open: false,
      message: '',
    });
  };
  setMessageInt = (id, severity) => {
    const { intl } = this.props;
    this.setState({
      open: true,
      message: intl.formatMessage({
        id, // This ID could be anything else as well
      }),
      severity: severity || SEVERITY_ERROR,
    });
  };
  setMessage = (message, severity) => {
    let _severity = !!severity ? severity : this.state.severity;
    let _message = '';
    try {
      const error = JSON.parse(message);
      if (!!error && !!error.errorCode && !!error.messageES) {
        _message = error.messageES;
        _severity = SEVERITY_ERROR;
      }
    } catch (e) {
      if (!!message && !!message.message) {
        _message = message.message;
      } else if (isString(message)) {
        _message = message;
      } else {
        _message = JSON.stringify(message);
      }
    }

    this.setState({
      open: true,
      message: _message,
      severity: _severity,
    });
  };

  render() {
    const { classes } = this.props;
    const { open, message, severity } = this.state;
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        >
          <Alert onClose={this.handleClose} severity={severity}>
            {message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

NotificationSnack.propTypes = {
  classes: PropTypes.object.isRequired,
};

/**
 *
 * @param message
 * @param severity
 */
export function showMessage(message, severity) {
  setMessageFn(message, severity);
}

export function showMessageInt(id, severity) {
  setMessageInt(id, severity);
}

export default injectIntl(withStyles(styles)(NotificationSnack));
