import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import AddressCardComponent from './components/addressCardComponent/AddressCardComponent';
import ContactCardComponent from './components/contactCardComponent/ContactCardComponent';
import InfoCardComponent from './components/infoCardComponent/InfoCardComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
}));

const DeploymentCardComponent = ({ deployment, recordingPolicy }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <InfoCardComponent
          id={deployment?._id}
          codename={deployment?.codename}
          description={deployment?.description}
          recordingPolicy={recordingPolicy}
        />
      </Grid>
      <Grid item xs={12}>
        <AddressCardComponent address={deployment?.place} />
      </Grid>
      <Grid item xs={12}>
        <ContactCardComponent contacts={deployment?.contacts} />
      </Grid>
    </Grid>
  );
};

DeploymentCardComponent.propTypes = {
  deployment: PropTypes.object.isRequired,
  recordingPolicy: PropTypes.object,
};

export default DeploymentCardComponent;
