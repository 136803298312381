import React from 'react';

import AbstractTable from '../../../shared/abstractTable/AbstractTable';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { GRAY } from '../../../../utils/constants';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import ViewModuleRoundedIcon from '@material-ui/icons/ViewModuleRounded';
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import { useHistory } from 'react-router';

import DeleteIcon from '@material-ui/icons/Delete';
import { deleteFilteredMosaic } from '../../../../services/commonServices';
import { tenantFiltersMosaicButton } from '../../../../utils/tenantStyles';
import EditIcon from '@material-ui/icons/Edit';
import { buildUrl } from '../../../../utils/axiosRequests';
import { ROUTE_DEPLOYMENT_GROUP_MOSAIC } from '../../../../utils/routes';

const useStyles = makeStyles((theme) => ({
  alertTable: {
    margin: '0 auto',
    boxShadow: '4px 4px 20px rgba(38, 37, 37, 0.1)',
  },
  iconContainer: {
    width: 34,
    height: 34,
    border: '1px solid black',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrowIcon: {
    transform: 'rotate(225deg)',
  },
  delete: {
    color: GRAY,
    '&:hover': {
      color: theme.palette.primary.main + '!important',
    },
  },
  secondaryButton: tenantFiltersMosaicButton(theme),
  cameraMosaicsTable: {
    width: '100%',
  },
}));

const MosaicListComponent = ({
  handleSetMosaicSelected,
  handleOpenMosaicForm,
  mosaicList,
  getMosaics,
  handleSetMosaicList,
  handleViewMosaic,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();

  const handleGoToMosaicEdit = (data) => {
    handleSetMosaicSelected(data);
    handleOpenMosaicForm(false);
  };

  const handleGoToMosaic = (mosaic_id) => {
    const url = buildUrl(ROUTE_DEPLOYMENT_GROUP_MOSAIC, { mosaic_id });
    history.push(url);
  };
  const handleDeleteMosaic = (mosaic_id) => {
    deleteFilteredMosaic(mosaic_id, handleGetMosaics);
  };

  const handleGetMosaics = () => {
    getMosaics(handleSetMosaicList);
  };

  const MOSAIC_BUTTON = intl.formatMessage({ id: 'see_mosaic' });
  const MOSAIC_DELETE = intl.formatMessage({ id: 'filter_button_delete' });
  const MOSAIC_EDIT = intl.formatMessage({ id: 'filter_button_edit' });
  const MOSAIC_VIEW = intl.formatMessage({ id: 'view_cameras' });

  return (
    <div>
      {mosaicList && (
        <AbstractTable
          className={classes.cameraMosaicsTable}
          columns={[
            {
              title: intl.formatMessage({ id: 'filters_name' }),
              field: 'name',
              render: (rowData) => <Typography>{rowData.mosaic_name}</Typography>,
            },
            {
              title: intl.formatMessage({ id: 'filters_description' }),
              field: 'description',
              render: (rowData) => <Typography>{rowData.mosaic_description}</Typography>,
            },
            {
              title: intl.formatMessage({ id: 'filters_time' }),
              field: 'time',
              render: (rowData) => <Typography>{rowData.mosaic_config.time / 1000}</Typography>,
            },
            {
              title: intl.formatMessage({ id: 'filter_groups' }),
              render: (rowData) => <Typography>{rowData.mosaic_config.camera_lists.length}</Typography>,
              sorting: false,
              draggable: false,
              headerStyle: {
                textAlign: 'center',
                minWidth: 10,
                padding: '0px !important',
              },
              cellStyle: {
                textAlign: 'center',
                minWidth: 10,
              },
            },
            {
              title: <div className={classes.headerButtonContainer} />,
              render: (rowData) => (
                <div>
                  <Tooltip title={MOSAIC_VIEW}>
                    <span>
                      <IconButton className={classes.delete} onClick={() => handleViewMosaic(rowData)}>
                        <VideocamRoundedIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={MOSAIC_EDIT}>
                    <span>
                      <IconButton className={classes.delete} onClick={() => handleGoToMosaicEdit(rowData)}>
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={MOSAIC_DELETE}>
                    <span>
                      <IconButton onClick={() => handleDeleteMosaic(rowData._id)} className={classes.delete}>
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={MOSAIC_BUTTON}>
                    <span>
                      <IconButton className={classes.delete} onClick={() => handleGoToMosaic(rowData._id)}>
                        <ViewModuleRoundedIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              ),
              headerStyle: {
                textAlign: 'right',
              },
              cellStyle: {
                textAlign: 'right',
              },
              sorting: false,
              draggable: false,
            },
          ]}
          data={mosaicList}
          options={{
            showEmptyDataSourceMessage: true,
            doubleHorizontalScroll: false,
            pageSize: 5,
            pageSizeOptions: [mosaicList.length < 5 && mosaicList.length > 0 ? mosaicList.length : 0, 5, 10, 25, 50],
            search: false,
            toolbar: false,
            headerStyle: {
              backgroundColor: GRAY,
              color: 'white',
              fontWeight: 'bold',
            },
            rowStyle: {
              padding: '0px !important',
            },
          }}
          style={{ boxShadow: '4px 4px 20px rgba(38, 37, 37, 0.1)' }}
        />
      )}
    </div>
  );
};

export default MosaicListComponent;
