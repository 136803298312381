import React, { useCallback, useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import CustomerTableComponent from './components/customerTableComponent/CustomerTableComponent';
import { getCustomerList } from '../../services/commonServices';
import PageHeaderComponent from '../common/pageHeaderComponent/PageHeaderComponent';
import withAuthorization from '../shared/auth/withAuthorization';
import { BREADCRUMB_CUSTOMER_lIST, PERMISSION_CUSTOMER_VIEW } from '../../utils/constants';
import { ROUTE_CUSTOMER } from '../../utils/routes';
import { buildUrl } from '../../utils/axiosRequests';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 0,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0, 20),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 6),
      marginBottom: theme.spacing(6),
    },
  },
  table: {
    width: '100%',
    margin: theme.spacing(0, 'auto'),
  },
}));

const CustomerListComponent = () => {
  const [data, setData] = useState([]);
  const { keycloak, initialized } = useKeycloak();
  const classes = useStyles();
  const history = useHistory();

  const getCustomers = useCallback(() => {
    if (initialized && keycloak.authenticated) {
      const successCustomerList = (response) => {
        if (response.data.length === 1) {
          const url = buildUrl(ROUTE_CUSTOMER, { customer: response.data[0]._id });
          history.push(url);
        } else {
          setData(response.data);
        }
      };
      getCustomerList(keycloak, successCustomerList);
    }
  }, [initialized, keycloak, history]);

  useEffect(() => {
    getCustomers();
  }, [getCustomers]);

  return (
    <div className={classes.root}>
      <PageHeaderComponent type={BREADCRUMB_CUSTOMER_lIST} />
      <div className={classes.table}>
        <CustomerTableComponent customerList={data} />
      </div>
    </div>
  );
};

export default withAuthorization(PERMISSION_CUSTOMER_VIEW)(CustomerListComponent);
