export const styles = () => ({
  root: {
    height: '2rem',
    width: '2rem',
  },
  img: {
    height: '100%',
    width: '100%',
  },
});
