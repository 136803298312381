import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { styles } from './InstallationsIconStyles';
import svg from '../../../../images/industry.svg';

const useStyles = makeStyles((theme) => styles(theme));

const InstallationsIconComponent = ({ tooltipText }) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div className={classes.root}>
      <Tooltip title={intl.formatMessage({ id: tooltipText })}>
        <img alt='' className={classes.img} src={svg} />
      </Tooltip>
    </div>
  );
};
InstallationsIconComponent.propTypes = {
  tooltipText: PropTypes.string,
};

export default InstallationsIconComponent;
