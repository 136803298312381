import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { ROUTE_DEPLOYMENT, ROUTE_DEPLOYMENT_LIST, ROUTE_CUSTOMER_LIST } from '../../utils/routes';

import { AXEGA_CUSTOMER, CSA_CUSTOMER, TIIVII_API_DEPLOYMENT_LIST_BY_USER } from '../../utils/constants';
import { buildUrl } from '../../utils/axiosRequests';
import { getContractsList, getCustomerList, getCustomerStyles } from '../../services/commonServices';
import { setStylesActionCreator } from '../../actions/commonActions';
import { TENANT_STYLES } from '../../utils/tenantStyles';
import UELogo from '../../images/Logo_EU_ATEMPO.png';
import { useDispatch } from 'react-redux';

const LandingComponent = () => {
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();
  const history = useHistory();

  const getDeployments = useCallback(
    (customer) => {
      const urlByUser = buildUrl(TIIVII_API_DEPLOYMENT_LIST_BY_USER, {
        username: keycloak.profile.username,
      });

      const successContractList = (response) => {
        if (response.data.length === 1) {
          const url = buildUrl(ROUTE_DEPLOYMENT, {
            customer,
            deployment_id: response.data[0].deployment_id,
          });
          history.push(url);
        } else {
          const url = buildUrl(ROUTE_DEPLOYMENT_LIST, { customer });
          history.push(url);
        }
      };
      getContractsList(urlByUser, successContractList);
    },
    [history, keycloak],
  );

  const getCustomerStylesCallback = (response, customer) => {
    if (Object.keys(response?.data).length === 0) {
      dispatch(setStylesActionCreator(TENANT_STYLES));
    } else {
      const hasSecondaryLogo = customer === CSA_CUSTOMER || customer === AXEGA_CUSTOMER;

      const styles = {
        ...response.data,
        ...(hasSecondaryLogo && { secondaryLogo: UELogo }),
      };
      dispatch(setStylesActionCreator(styles));
    }
  };

  const getCustomers = useCallback(() => {
    const successCustomerList = (response) => {
      if (response.data.length > 1) {
        history.push(ROUTE_CUSTOMER_LIST);
      } else {
        const customerId = response.data[0]._id;
        getDeployments(response.data[0]._id);

        getCustomerStyles(customerId, (r) => getCustomerStylesCallback(r, customerId));
      }
    };

    getCustomerList(keycloak, successCustomerList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDeployments, history, keycloak]);

  useEffect(() => {
    if (initialized) {
      getCustomers();
    }
  }, [initialized, getCustomers]);

  useEffect(() => {
    if (initialized && !!keycloak && !keycloak.authenticated) {
      keycloak.login();
    }
  }, [initialized, keycloak]);

  return <></>;
};

export default LandingComponent;
