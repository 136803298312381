export const MAX_CAMERA_SPEED = 100;
export const MIN_CAMERA_SPEED = 0;
export const DEFAULT_CAMERA_SPEED = 20;

export const MIN_DEAD_ZONE = 0;
export const MAX_DEAD_ZONE = 1;
export const DEFAULT_VALUE_DEAD_ZONE = 0.08;
export const STEP_DEAD_ZONE = 0.01;

export const MAX_PAN_TILT_VALUE = 100;

export const REQUEST_HYSTERESIS = 200;

export const LEFT_STICK_Y = 'LeftStickY';
export const LEFT_STICK_X = 'LeftStickX';
export const RIGHT_STICK_Y = 'RightStickY';
export const RIGHT_STICK_X = 'RightStickX';

export const LEFT_TRIGGER = 'LeftTrigger';
export const RIGHT_TRIGGER = 'RightTrigger';

const KEY_UP = 'w';
const KEY_DOWN = 's';
const KEY_RIGHT = 'd';
const KEY_LEFT = 'a';
const KEY_ZOOM_IN = 'arrowup';
const KEY_ZOOM_OUT = 'arrowdown';
const PTZ_SPEED_MOVING = 1;
export const PTZ_STOP = 0;
export const PTZ_KEYBOARD = {
  map: {
    pan: 'PAN',
    tilt: 'TILT',
    zoom: 'ZOOM',
  },
};

export const PTZ_GAMEPAD = {
  map: {
    pan: LEFT_STICK_X,
    tilt: LEFT_STICK_Y,
    zoom: RIGHT_STICK_Y,
  },
};

export const PTZ_KEYS_CONTROL = {
  [KEY_UP]: {
    value: PTZ_SPEED_MOVING,
    type: 'TILT',
  },
  [KEY_DOWN]: {
    value: -PTZ_SPEED_MOVING,
    type: 'TILT',
  },
  [KEY_RIGHT]: {
    value: PTZ_SPEED_MOVING,
    type: 'PAN',
  },
  [KEY_LEFT]: {
    value: -PTZ_SPEED_MOVING,
    type: 'PAN',
  },
  [KEY_ZOOM_IN]: {
    value: PTZ_SPEED_MOVING,
    type: 'ZOOM',
  },
  [KEY_ZOOM_OUT]: {
    value: -PTZ_SPEED_MOVING,
    type: 'ZOOM',
  },
};

const KEY_ONE = '1';
const KEY_TWO = '2';
const KEY_THREE = '3';
const KEY_FOUR = '4';
const KEY_FIVE = '5';
const KEY_SIX = '6';
const KEY_SEVEN = '7';
const KEY_EIGHT = '8';

export const PTZ_KEY_PRESET = {
  [KEY_ONE]: {
    value: '01',
  },
  [KEY_TWO]: {
    value: '02',
  },
  [KEY_THREE]: {
    value: '03',
  },
  [KEY_FOUR]: {
    value: '04',
  },
  [KEY_FIVE]: {
    value: '05',
  },
  [KEY_SIX]: {
    value: '06',
  },
  [KEY_SEVEN]: {
    value: '07',
  },
  [KEY_EIGHT]: {
    value: '08',
  },
};

export const DEFAULT_GAMEPAD_LAYOUT = [
  'A',
  'B',
  'X',
  'Y',
  'LB',
  'RB',
  'LT',
  'RT',
  'Back',
  'Start',
  'LS',
  'RS',
  'DPadUp',
  'DPadDown',
  'DPadLeft',
  'DPadRight',
  'Start',
];
export const LINUX_FIREFOX_GAMEPAD_LAYOUT = [
  'X',
  'A',
  'B',
  'Y',
  'LB',
  'RB',
  'LT',
  'RT',
  'Back',
  'Start',
  'LS',
  'RS',
  'DPadUp',
  'DPadDown',
  'DPadLeft',
  'DPadRight',
  'Start',
];

export const REQUEST_TYPE_PAN_TILT = 'PAN_TILT';
export const REQUEST_TYPE_ZOOM = 'ZOOM';

export const BUTTON_PRESET_DIRECT_RB = 'RB';
export const BUTTON_PRESET_DIRECT_LB = 'LB';

export const BUTTON_MODIFIER = 'LT';

export const CAMERA_PRESET_MAP = {
  X: 1,
  Y: 2,
  B: 3,
  A: 4,
};

export const EXTENDED_CAMERA_PRESET_MAP = {
  DPadUp: 6,
  DPadRight: 7,
  DPadDown: 8,
  DPadLeft: 5,
};

export const PTZ_KEYS = [
  KEY_UP,
  KEY_DOWN,
  KEY_RIGHT,
  KEY_LEFT,
  KEY_ZOOM_IN,
  KEY_ZOOM_OUT,
  KEY_ONE,
  KEY_TWO,
  KEY_THREE,
  KEY_FOUR,
  KEY_FIVE,
  KEY_SIX,
  KEY_SEVEN,
  KEY_EIGHT,
];

export const AXIS_PTZ_CAMERA_PRESET_SEPARATOR = 'Preset Positions for camera ';

export const PRESET_BUTTONS = ['LT+X', 'LT+Y', 'LT+B', 'LT+A', 'Pad left', 'Pad up', 'Pad right', 'Pad down'];

export const DEVICE_KEYBOARD = 'keyboard';
export const DEVICE_GAMEPAD = 'gamepad';

export const PRESETS_CONTROLS = [
  ['01', 'LT+X'],
  ['02', 'LT+Y'],
  ['03', 'LT+B'],
  ['04', 'LT+A'],
  ['05', 'Pad left'],
  ['06', 'Pad up'],
  ['07', 'Pad right'],
  ['08', 'Pad down'],
];
