import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { useIntl } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import { styles } from './NewUserFormStyles';
import { HTTP_CODE_409, MESSAGE_TIMEOUT, MIN_HEIGHT, SEVERITY_ERROR } from '../../../utils/constants';
import Button from '@material-ui/core/Button';
import { showMessage } from '../../shared/notificationSnack/NotificationSnack';
import { useKeycloak } from '@react-keycloak/web';
import { getUserInfo, postAccessToClientService, postNewUserService } from '../../../services/commonServices';
import { Checkbox, FormControlLabel, MenuItem, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  ...styles(theme, { MIN_HEIGHT }),
  checkboxLabel: {
    color: 'gray',
  },
  dateSelectorWithoutValue: {
    color: 'gray',
  },
  dateSelectorWithValue: {
    color: 'black',
  },
}));

const NewUserFormComponent = ({ handleNewUserModal, customer, handleGetUserList }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { keycloak } = useKeycloak();

  const [partnerId, setPartnerId] = useState(null);

  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const availableRoles = useSelector((state) => state.commonState.manageableUserRoles);
  const [selectedRole, setSelectedRole] = useState('');
  const [isTemporary, setIsTemporary] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [startDateInMilis, setStartDateInMilis] = useState('');
  const [endDate, setEndDate] = useState('');
  const [endDateInMilis, setEndDateInMilis] = useState('');

  const [successMessage, setSuccessMessage] = useState(false);
  const [failureMessage, setFailureMessage] = useState(false);
  const [alreadyExists, setAlreadyExistsMessage] = useState(false);

  const invalidUsernameChars = ['_'];

  const resetInputValues = () => {
    handleUsername('');
    handlePassword('');
    handleName('');
    handleDescription('');
    handleEmail('');
    handleTelephone('');
  };

  useEffect(() => {
    resetInputValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePartnerId = (response) => {
    setPartnerId(response.data._id);
  };

  useEffect(() => {
    if (keycloak) {
      keycloak
        .loadUserProfile()
        .then((profile) => {
          getUserInfo(profile.username, handlePartnerId);
        })
        .catch((error) => showMessage(error, SEVERITY_ERROR));
    }
  }, [keycloak]);

  const handleUsername = (value) => {
    const regex = new RegExp(`[${invalidUsernameChars.join('')}]`, 'g');
    const newValue = value.replace(regex, '');
    setUsername(newValue);
  };

  const handlePassword = (value) => {
    setPassword(value);
  };

  const handleRole = (value) => {
    setSelectedRole(value);
  };

  const handleName = (value) => {
    setName(value);
  };

  const handleDescription = (value) => {
    setDescription(value);
  };

  const handleEmail = (value) => {
    setEmail(value);
  };

  const handleTelephone = (value) => {
    setTelephone(value);
  };

  const handleIsTemporaryChange = (event) => {
    setIsTemporary(event.target.checked);
  };

  const handleStartDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const timeInMillis = selectedDate.getTime() / 1000;
    setStartDate(event.target.value);
    setStartDateInMilis(timeInMillis);
  };

  const handleEndDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const timeInMillis = selectedDate.getTime() / 1000;
    setEndDate(event.target.value);
    setEndDateInMilis(timeInMillis);
  };

  const handleSetSuccessMessage = (value) => {
    setSuccessMessage(value);
  };

  const handleSetFailureMessage = (value) => {
    setFailureMessage(value);
  };

  const handleSetAlreadyExistsMessage = (value) => {
    setAlreadyExistsMessage(value);
  };

  const handleUpdateSuccess = (response) => {
    handleSetSuccessMessage(true);
    postAccessToClientService(response.data, customer._id, handleGetUserList);

    setTimeout(() => {
      handleSetSuccessMessage(false);
      resetInputValues();
      handleNewUserModal();
    }, MESSAGE_TIMEOUT);
  };

  const handleUpdateFailure = (error) => {
    error?.response?.status === HTTP_CODE_409 ? handleSetAlreadyExistsMessage(true) : handleSetFailureMessage(true);

    setTimeout(() => {
      error?.response?.status === HTTP_CODE_409 ? handleSetAlreadyExistsMessage(false) : handleSetFailureMessage(false);
    }, MESSAGE_TIMEOUT);
  };

  const handleCreateNewUser = () => {
    const body = {
      credentials: {
        username,
        password,
      },
      is_temporary: isTemporary,
      description,
      roles: [selectedRole],
      person: {
        email,
        name,
        telephone,
      },
      owned_by_partner_id: partnerId,
      ...(isTemporary && {
        valid_since: startDateInMilis,
        valid_until: endDateInMilis,
      }),
    };

    postNewUserService(body, handleUpdateSuccess, handleUpdateFailure);
  };

  const validatePassword = () => {
    if (isTemporary) {
      return !!username && !!password && !!name && !!startDate && !!endDate;
    }
    return !!username && !!password && !!name;
  };

  const getLabelWithAsterisk = (label) => {
    return isTemporary ? `${label} *` : label;
  };

  return (
    <form className={classes.textFieldContainer}>
      <div className={classes.textFieldBlock}>
        <TextField
          id='user-username'
          type='text'
          required
          value={username}
          label={intl.formatMessage({ id: 'user_username' })}
          className={classes.textField}
          onChange={(e) => handleUsername(e.target.value)}
        />
        <TextField
          id='user-password'
          type='password'
          required
          value={password}
          label={intl.formatMessage({ id: 'user_password' })}
          className={classes.textField}
          onChange={(e) => handlePassword(e.target.value)}
        />
        <TextField
          id='user-role'
          required
          select
          label={intl.formatMessage({ id: 'user_role' })}
          className={classes.textField}
          onChange={(e) => handleRole(e.target.value)}
        >
          {availableRoles?.map((role, index) => (
            <MenuItem key={index} value={role}>
              {intl.formatMessage({ id: `role_${role}` })}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div>
        <FormControlLabel
          control={<Checkbox checked={isTemporary} onChange={handleIsTemporaryChange} color='primary' />}
          label={intl.formatMessage({ id: 'user_temporary' })}
          className={classes.checkboxLabel}
        />
        {isTemporary && (
          <>
            <TextField
              id='start-date'
              label={getLabelWithAsterisk(intl.formatMessage({ id: 'user_start_date' }))}
              type='date'
              value={startDate}
              onChange={(e) => handleStartDateChange(e)}
              className={classes.dateField}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={
                startDate === ''
                  ? {
                      classes: {
                        input: classes.dateSelectorWithoutValue,
                      },
                    }
                  : {
                      classes: {
                        input: classes.dateSelectorWithValue,
                      },
                    }
              }
            />
            <TextField
              id='end-date'
              label={getLabelWithAsterisk(intl.formatMessage({ id: 'user_end_date' }))}
              type='date'
              value={endDate}
              onChange={handleEndDateChange}
              className={classes.dateField}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={
                endDate === ''
                  ? {
                      classes: {
                        input: classes.dateSelectorWithoutValue,
                      },
                    }
                  : {
                      classes: {
                        input: classes.dateSelectorWithValue,
                      },
                    }
              }
            />
          </>
        )}
      </div>
      <TextField
        id='user-name'
        type='text'
        label={intl.formatMessage({ id: 'user_name' })}
        required
        value={name}
        className={classes.textField}
        onChange={(e) => handleName(e.target.value)}
      />
      <TextField
        id='user-description'
        type='text'
        label={intl.formatMessage({ id: 'user_description' })}
        value={description}
        className={classes.textField}
        onChange={(e) => handleDescription(e.target.value)}
      />
      <div className={classes.textFieldBlock}>
        <TextField
          id='user-email'
          type='text'
          label={intl.formatMessage({ id: 'user_email' })}
          value={email}
          className={classes.textField}
          onChange={(e) => handleEmail(e.target.value)}
        />
        <TextField
          id='user-telephone'
          type='text'
          label={intl.formatMessage({ id: 'user_telephone' })}
          value={telephone}
          className={classes.textField}
          onChange={(e) => handleTelephone(e.target.value)}
        />
      </div>
      <div className={classes.buttonContainer}>
        <Button
          onClick={handleNewUserModal}
          className={classes.secondaryButton}
          classes={{ disabled: classes.disabled }}
        >
          {intl.formatMessage({ id: 'close' })}
        </Button>
        <Button
          onClick={handleCreateNewUser}
          disabled={!validatePassword()}
          className={classes.primaryButton}
          classes={{ disabled: classes.disabled }}
        >
          {intl.formatMessage({ id: 'user_add' })}
        </Button>
      </div>
      {successMessage && (
        <Typography variant='subtitle2' color='secondary'>
          {intl.formatMessage({ id: 'user_new_success' })}
        </Typography>
      )}
      {failureMessage && (
        <Typography variant='subtitle2' color='secondary'>
          {intl.formatMessage({ id: 'user_new_error' })}
        </Typography>
      )}
      {alreadyExists && (
        <Typography variant='subtitle2' color='secondary'>
          {intl.formatMessage({ id: 'user_new_already_exists' })}
        </Typography>
      )}
    </form>
  );
};

export default NewUserFormComponent;
