import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import VideoHlsComponent from '../../shared/VideoHlsComponent';
import { getLiveUrl, getRecUrl } from '../../../services/commonServices';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPlayingActionCreator,
  setSecondsActionCreator,
  turnOffLoadingActionCreator,
  turnOnLoadingActionCreator,
} from '../../../actions/commonActions';
import {
  GET_URL_INTERVAL,
  GET_URL_INTERVAL_NUMBER_OF_TRIES,
  HTTP_CODE_401,
  HTTP_CODE_422,
  LEMBRA001,
  STATE_URL_AVAILABLE,
  STATE_URL_ERROR,
  STATE_URL_PENDING,
  TIIVII_API_HOST,
  VIDEO_LEMBRA_ERROR_TIMEOUT,
} from '../../../utils/constants';
import { showMessage } from '../../shared/notificationSnack/NotificationSnack';
import { useIntl } from 'react-intl';
import { createFullDate, onError, onSuccess } from '../../../utils/utils';
import format from 'date-fns/format';
import PtzComponent from '../ptz/PtzComponent';
import { centerPtzService, getLiveMjpeg } from '../../../services/ptzService';
import MouseControlComponent from '../mouseControlComponent/MouseControlComponent';
import { storeVideoDimensionsActionCreator } from '../../../actions/ptzActions';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { SVG_RADIUS } from '../mouseControlComponent/MouseControlConstants';

const useStyles = makeStyles(() => ({
  videoContainer: {
    position: 'relative',
  },
  image: {
    userSelect: 'none',
  },
}));

const VideoComponent = ({
  mute,
  poster,
  filters,
  live,
  camera,
  detail,
  videoSize,
  mosaicId,
  displayVideoPlayers,
  fullScreen,
  camerasList,
  deployment_id,
}) => {
  const classes = useStyles();
  const videoRef = useRef();
  const imageRef = useRef();
  const svgRef = useRef();
  const timeoutUrlRef = useRef(null);

  const intl = useIntl();
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();

  let petition = true;
  let petitionsCount = 0;
  const { timeFrom = 0, timeTo = 0, dateFrom = new Date(), dateTo = new Date() } = filters || {};

  const { customer_id } = useParams();

  const from = format(createFullDate(dateFrom, +timeFrom * 1000), 't');
  const to = format(createFullDate(dateTo, +timeTo * 1000), 't');

  const connectedCamera = useSelector((state) => state.ptzState.connectedCamera);
  const cameraReference = useSelector((state) => state.commonState.cameraReference);
  const seconds = useSelector((state) => state.commonState.seconds);
  const rewind = useSelector((state) => state.commonState.rewind);
  const fastForward = useSelector((state) => state.commonState.fastForward);
  const playing = useSelector((state) => state.commonState.playing);
  const filteredCameraList = useSelector((state) => state.commonState.filteredCameraList);
  const [url, setUrl] = useState('');
  const [cameraImg, setCameraImg] = useState(null);
  const [enableCameraHandling, setEnableCameraHandling] = useState(connectedCamera === camera._id);
  const [allowCenterRequest, setAllowCenterRequest] = useState(true);
  const lmbrErrorDisplayed = useRef(false);

  const storeVideoDimensions = (data) => {
    dispatch(storeVideoDimensionsActionCreator(data));
  };

  const setPlaying = useCallback(
    (data) => {
      dispatch(setPlayingActionCreator({ playing: data }));
    },
    [dispatch],
  );

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      petition = false;
    };
  }, []);

  useEffect(() => {
    setUrl('');
  }, [filters]);

  const setSeconds = (data) => {
    dispatch(setSecondsActionCreator({ seconds: data }));
  };

  const turnOnLoading = useCallback(() => {
    dispatch(turnOnLoadingActionCreator());
  }, [dispatch]);

  const turnOffLoading = useCallback(() => {
    dispatch(turnOffLoadingActionCreator());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleRecUrlResponse = (response) => {
    switch (response.data.status) {
      case STATE_URL_PENDING:
        if (petitionsCount < GET_URL_INTERVAL_NUMBER_OF_TRIES) {
          petitionsCount++;
          setTimeout(() => {
            getUrl();
          }, GET_URL_INTERVAL);
        } else turnOffLoading();
        return;
      case STATE_URL_AVAILABLE:
        setPlaying(true);
        turnOffLoading();
        setUrl(response.data.URL);
        return;
      case STATE_URL_ERROR:
        showMessage(intl.formatMessage({ id: 'url_error' }));
        return;
      default:
        return;
    }
  };

  const handleLiveUrlResponse = (response) => {
    if (response.data.URL_live) {
      setPlaying(true);
      turnOffLoading();
      setUrl(response.data.URL_live);
    } else {
      petitionsCount++;
      setTimeout(() => {
        if (petitionsCount < GET_URL_INTERVAL_NUMBER_OF_TRIES) {
          getUrl();
        }
      }, GET_URL_INTERVAL);
    }
  };

  const handleVideoError = (error) => {
    turnOffLoading();
    if (error?.status === HTTP_CODE_401) {
      keycloak.logout();
    }

    if (error?.response?.status === HTTP_CODE_422 && error?.response?.data?.error === LEMBRA001) {
      clearTimeout(timeoutUrlRef.current);
      timeoutUrlRef.current = setTimeout(() => {
        getUrl(false);
        if (!lmbrErrorDisplayed.current) {
          lmbrErrorDisplayed.current = true;
          showMessage(intl.formatMessage({ id: 'error__lembra001' }, { camera_id: camera.description }));
        }
      }, VIDEO_LEMBRA_ERROR_TIMEOUT);
    } else if (error?.response?.status === HTTP_CODE_422) {
      showMessage(intl.formatMessage({ id: 'recording_not_available' }));
    } else {
      if (url !== '') {
        setUrl('');
      }
    }
  };

  const getUrl = (loading = true) => {
    if (!petition) {
      return;
    }

    if (loading && !mosaicId) {
      turnOnLoading();
    }

    if (live) {
      if (enableCameraHandling) {
        void getLiveMjpeg(camera._id, handleMjpegUrl, onError);
      } else {
        getLiveUrl(camera.deployment_id, camera._id, handleLiveUrlResponse, handleVideoError);
      }
    } else if (displayVideoPlayers || fullScreen) {
      getRecUrl(camera.deployment_id, camera._id, handleRecUrlResponse, handleVideoError, +from, +to);
    }
  };

  useEffect(() => {
    if (
      camera !== null &&
      (!!mosaicId ||
        ((live || (!live && displayVideoPlayers) || fullScreen) &&
          filteredCameraList[deployment_id]?.find((cam) => cam._id === camera._id)) ||
        customer_id)
    ) {
      getUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera, displayVideoPlayers, live, filters, fullScreen, enableCameraHandling]);

  const handleMjpegUrl = (response) => {
    const url = `${TIIVII_API_HOST}${response.data.url_mjpeg}`;
    setCameraImg(url);
  };

  useEffect(() => {
    if (enableCameraHandling) {
      setUrl('');
      getUrl(false);
    }

    return () => {
      setUrl('');
      clearTimeout(timeoutUrlRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEnableCameraHandling(camerasList.length === 1 && connectedCamera === camera._id);
  }, [connectedCamera, camera._id, camerasList.length]);

  const handleCenterCamera = (event) => {
    if (!allowCenterRequest) {
      return;
    }

    const rect = event.target.getBoundingClientRect();

    const x = Math.round(event.clientX - rect.left);
    const y = Math.round(event.clientY - rect.top);

    const circleDiameter = SVG_RADIUS * 2;
    if (x <= circleDiameter || y <= circleDiameter) {
      return;
    }

    const parameters = {
      center_x: x,
      center_y: y,
      width: Math.round(rect.width),
      height: Math.round(rect.height),
    };

    centerPtzService(camera._id, parameters, onSuccess, onError);
  };

  const handleImageLoaded = () => {
    storeVideoDimensions({
      width: imageRef.current?.clientWidth,
      height: imageRef.current?.clientHeight,
    });
  };

  return (
    <div onMouseDown={enableCameraHandling ? handleCenterCamera : void 0} className={classes.videoContainer}>
      {enableCameraHandling && (
        <>
          <MouseControlComponent
            cameraId={camera._id}
            fullScreen={fullScreen}
            disableCenterRequest={() => setAllowCenterRequest(false)}
            enableCenterRequest={() => setAllowCenterRequest(true)}
            svgRef={svgRef}
            imageRef={imageRef}
          />
          <PtzComponent cameraId={camera._id} />
        </>
      )}
      {enableCameraHandling ? (
        <img
          ref={imageRef}
          className={classes.image}
          height={fullScreen ? '100%' : videoSize}
          width={'100%'}
          src={cameraImg}
          alt={'Mjpeg'}
          onLoad={handleImageLoaded}
        />
      ) : (
        <VideoHlsComponent
          videoRef={videoRef}
          authorization={keycloak}
          mute={mute}
          playing={playing}
          setPlaying={setPlaying}
          rewind={rewind}
          fastForward={fastForward}
          poster={poster}
          video={url}
          storeSeconds={seconds}
          setSeconds={setSeconds}
          live={live}
          detail={detail}
          camera={camera}
          cameraReference={cameraReference}
          videoSize={videoSize}
          fullScreen={fullScreen}
          storeVideoDimensions={storeVideoDimensions}
        />
      )}
    </div>
  );
};

VideoComponent.propTypes = {
  mute: PropTypes.bool.isRequired,
  poster: PropTypes.string.isRequired,
  filters: PropTypes.any,
  live: PropTypes.bool.isRequired,
  deployment_id: PropTypes.any,
  camera: PropTypes.object,
};

export default VideoComponent;
