import { GRAY } from '../../../../utils/constants';
import { convertHexToFilter } from '../../../../utils/colorUtils';

export const styles = (theme) => ({
  root: {
    height: 40,
    width: 40,
    filter: convertHexToFilter(GRAY),
    '&:hover': {
      filter: convertHexToFilter(theme.palette.primary.main),
    },
    '&.Mui-disabled': {
      filter: convertHexToFilter(theme.palette.disabled.light),
    },
  },
  img: {
    height: '100%',
    width: '100%',
  },
});
