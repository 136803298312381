import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import MosaicTabsComponent from './mosaicFormComponent/components/mosaicTabsComponent/MosaicTabsComponent';
import { GRAY } from '../../../../utils/constants';
import { tenantPrimaryButton, tenantSecondaryButton } from '../../../../utils/tenantStyles';

const useStyles = makeStyles((theme) => ({
  modal: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
  },
  textContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    margin: theme.spacing(4, 0, 8, 0),
  },
  modalFilters: {
    width: '100%',
    marginLeft: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    boxSizing: 'border-box',
    height: '3rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    color: 'white',
    backgroundColor: GRAY,
    padding: '1rem',
  },
  delete: {
    border: '1px solid white',
    color: GRAY,
    '&:hover': {
      backgroundColor: 'white !important',
      borderColor: GRAY + '!important',
      color: GRAY + '!important',
    },
  },
  save: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(6),
  },
  verticalTabs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  verticalTabsDisabled: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
  },
  description: {
    minWidth: 300,
  },
  mosaicTextField: {
    color: GRAY + ' !important',
  },
  primaryButton: tenantPrimaryButton(theme),
  secondaryButton: tenantSecondaryButton(theme),
  disabled: {
    backgroundColor: theme.palette.disabled.light + '!important',
    border: 0 + '!important',
    color: theme.palette.disabled.main + '!important',
  },
  iconButton: {
    '&:hover': {
      color: theme.palette.primary.main + ' !important',
    },
  },
  textTitle: {
    fontWeight: 'normal',
    color: theme.palette.primary.main,
  },
}));

function ViewMosaicComponent({
  mosaic,
  handleCloseMosaicForm,
  savedFiltersMosaic,
  customerCameras,
  newMosaic,
  readMosaic,
}) {
  const classes = useStyles();
  const intl = useIntl();
  const [groupIndex, setGroupIndex] = useState(0);

  const [groups, setGroups] = useState(null);

  useEffect(() => {
    handleSetGroupIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mosaic]);

  useEffect(() => {
    if (mosaic) {
      handleSetGroups(mosaic.mosaic_config.camera_lists);
    }
  }, [mosaic]);

  const handleSetGroups = (value) => {
    setGroups(value);
  };

  const handleSetGroupIndex = (value) => {
    setGroupIndex(value);
  };

  return (
    <div className={classes.modal}>
      <div className={classes.textContainer}>
        <div>
          <Typography>{intl.formatMessage({ id: 'filters_name' })}</Typography>
          <Typography className={classes.textTitle}>{mosaic?.mosaic_name}</Typography>
        </div>
        <div>
          <Typography>{intl.formatMessage({ id: 'filters_description' })}</Typography>
          <Typography className={classes.textTitle}>{mosaic?.mosaic_description}</Typography>
        </div>
        <div>
          <Typography>{intl.formatMessage({ id: 'filters_time' })}</Typography>
          <Typography className={classes.textTitle}>{(mosaic?.mosaic_config?.time / 1000).toString()}</Typography>
        </div>
      </div>
      <div className={classes.verticalTabs}>
        <MosaicTabsComponent
          savedFiltersMosaic={savedFiltersMosaic}
          customerCameras={customerCameras}
          handleSetGroups={handleSetGroups}
          groups={groups}
          groupIndex={groupIndex}
          handleSetGroupIndex={handleSetGroupIndex}
          newMosaic={newMosaic}
          readMosaic={readMosaic}
        />
      </div>
      <div className={classes.save}>
        <Button onClick={handleCloseMosaicForm} className={classes.secondaryButton}>
          {intl.formatMessage({ id: 'close' })}
        </Button>
      </div>
    </div>
  );
}

export default ViewMosaicComponent;
