import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SwipeableDrawer,
  Toolbar,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import { useIntl } from 'react-intl';
import { useKeycloak } from '@react-keycloak/web';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import FlagIcon from './components/FlagIconComponent';
import CustomersIconComponent from '../common/customIcons/customersIconComponent/CustomersIconComponent';
import LoginIconComponent from '../common/customIcons/LoginIconComponent/LoginIconComponent';
import LogoutIconComponent from '../common/customIcons/LogoutIconComponent/LogoutIconComponent';
import {
  ROUTE_CUSTOMER_LIST,
  ROUTE_DEPLOYMENT_LIST,
  ROUTE_USER_MANAGEMENT,
  ROUTE_USER_PROFILE,
} from '../../utils/routes';
import {
  GRAY,
  LANGUAGES_ARRAY,
  I18N_CONFIG,
  LOCAL_STORAGE__LOCALE,
  PERMISSION_CUSTOMER_VIEW,
  PERMISSION_DEPLOYMENT_VIEW,
  PERMISSION_USER_VIEW,
  LOCAL_STORAGE__CUSTOMER_STYLES,
  CSA_CUSTOMER,
  AXEGA_CUSTOMER,
} from '../../utils/constants';
import AuthorizedElement from '../shared/auth/AuthorizedElement';
import { ROUTE_VIEW_LINK_MANAGEMENT } from '../../utils/routes';
import { LOCAL_STORAGE__CUSTOMER_ID } from '../../utils/constants';
import UserIconComponent from '../common/customIcons/userIconComponent/UserIconComponent';
import SettingsIconComponent from '../common/customIcons/settingsIconComponent/SettingsIconComponent';
import InstallationsIconComponent from '../common/customIcons/installationIconComponent/InstallationsIconComponent';
import { getCustomerStyles } from '../../services/commonServices';
import { setStylesActionCreator } from '../../actions/commonActions';
import { TENANT_STYLES } from '../../utils/tenantStyles';
import { getTenantFromUrl } from '../../utils/utils';
import { getServiceContractService } from '../../services/commonServices';
import LinkIconComponent from '../common/customIcons/linkIconComponent/LinkIconComponent';
import UELogo from '../../images/Logo_EU_ATEMPO.png';

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: 100,
    backgroundColor: theme.palette.white.main,
    [theme.breakpoints.down('sm')]: {
      height: 180,
    },
  },
  toolbar: {
    color: 'white',
    height: 100,
    [theme.breakpoints.down('sm')]: {
      height: 180,
    },
  },
  logoContainer: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center',
    height: '100%',
    padding: theme.spacing(0, 20),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: theme.spacing(0),
    },
  },
  imageHeader: {
    maxHeight: 40,
    marginTop: 8,
    '&:img': {
      maxWidth: '100%',
    },
  },
  secondLogo: {
    height: 80,
    '&:img': {
      maxWidth: '100%',
    },
  },
  brand: {
    variant: 'caption',
    fontSize: 14,
    marginLeft: theme.spacing(2),
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  menuButton: {
    position: 'absolute',
    right: 0,
    marginLeft: -12,
    marginRight: 20,
    cursor: 'pointer',
    color: GRAY,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  languageButton: {
    position: 'absolute',
    right: 80,
    height: 10,
    width: 10,
    color: GRAY,
    border: '2px solid white',
    fontSize: '1rem',
    fontWeight: 'bold',
    paddingBottom: theme.spacing(2),
    '&:hover': {
      border: `2px solid ${theme.palette.secondary.main}`,
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down('sm')]: {
      height: 20,
      width: 30,
      fontSize: '0.8rem',
    },
  },
  menuRow: {
    color: GRAY,
    '&:hover *': {
      color: theme.palette.primary.main + '!important',
    },
  },
}));

const TopMenuComponent = ({ handleLanguageChanged, language }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { keycloak } = useKeycloak();
  const { customer } = useParams();
  const [mainMenuOpen, setMainMenuOpen] = useState(false);
  const [languageMenuAnchor, setLanguageMenuAnchor] = useState(null);
  const location = useLocation();

  const [deploymentId, setDeploymentId] = useState('');
  const [customerId, setCustomerId] = useState('');
  const customerStyles = useSelector((state) => state.commonState.customerStyles);
  const localStorageCustomerStyles =
    JSON.parse(localStorage.getItem(LOCAL_STORAGE__CUSTOMER_STYLES))?.tenant === getTenantFromUrl()
      ? JSON.parse(localStorage.getItem(LOCAL_STORAGE__CUSTOMER_STYLES))
      : null;

  const handleLanguageMenu = (event) => {
    event ? setLanguageMenuAnchor(event.currentTarget) : setLanguageMenuAnchor(null);
  };

  const handleMainMenu = () => setMainMenuOpen(!mainMenuOpen);

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const deploymentIndex = pathSegments.findIndex((segment) => segment === 'deployments');
    const customerIndex = pathSegments.findIndex((segment) => segment === 'customers');
    if (deploymentIndex !== -1 && pathSegments.length > deploymentIndex + 1) {
      setDeploymentId(pathSegments[deploymentIndex + 1]);
    } else if (customerIndex !== -1 && pathSegments.length > customerIndex + 1) {
      setCustomerId(pathSegments[customerIndex + 1]);
    } else if (location.pathname.endsWith(ROUTE_CUSTOMER_LIST)) {
      dispatch(setStylesActionCreator(TENANT_STYLES));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (!!customer && !localStorage.getItem(LOCAL_STORAGE__CUSTOMER_ID)) {
      localStorage.setItem(LOCAL_STORAGE__CUSTOMER_ID, customer);
    }
  });

  useEffect(() => {
    if (!!keycloak && !!keycloak.token) {
      if (!!customerId) {
        if (!localStorage.getItem(LOCAL_STORAGE__CUSTOMER_ID)) {
          localStorage.setItem(LOCAL_STORAGE__CUSTOMER_ID, customerId);
        }
        getCustomerStyles(customerId, (r) => getCustomerStylesCallback(r, customerId));
      } else if (!!deploymentId) {
        getServiceContractService(deploymentId, (response) => {
          localStorage.setItem(LOCAL_STORAGE__CUSTOMER_ID, response.data[0].customer_id);
          getCustomerStyles(response.data[0].customer_id, (r) =>
            getCustomerStylesCallback(r, response.data[0].customer_id),
          );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak, keycloak.token, deploymentId, customerId]);

  const getCustomerStylesCallback = (response, customer) => {
    if (Object.keys(response?.data).length === 0) {
      dispatch(setStylesActionCreator(TENANT_STYLES));
    } else {
      const hasSecondaryLogo = customer === CSA_CUSTOMER || customer === AXEGA_CUSTOMER;

      const styles = {
        ...response.data,
        ...(hasSecondaryLogo && { secondaryLogo: UELogo }),
      };
      dispatch(setStylesActionCreator(styles));
    }
  };

  return (
    <div>
      <AppBar position='static' className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.logoContainer}>
            {!!customerStyles?.header_data?.logo ? (
              <img
                alt={customerStyles?.header_data?.logo.alt}
                src={
                  customerStyles?.header_data?.logo?.type === 'import'
                    ? customerStyles.header_data.logo.src
                    : 'data:image/png;base64,' + customerStyles.header_data.logo.src
                }
                className={classes.imageHeader}
              />
            ) : !!localStorageCustomerStyles?.header_data?.logo ? (
              <img
                alt={localStorageCustomerStyles?.header_data?.logo?.alt}
                src={
                  localStorageCustomerStyles?.header_data?.logo?.type === 'import'
                    ? localStorageCustomerStyles?.header_data?.logo?.src
                    : 'data:image/png;base64,' + localStorageCustomerStyles?.header_data?.logo?.src
                }
                className={classes.imageHeader}
              />
            ) : (
              <></>
            )}
            {(!!customerStyles?.secondaryLogo || !!localStorageCustomerStyles?.secondaryLogo) && (
              <img
                alt={'Logo UE'}
                src={customerStyles?.secondaryLogo || localStorageCustomerStyles.secondaryLogo}
                className={classes.secondLogo}
              />
            )}
          </div>
          <IconButton
            color='primary'
            aria-label='Language'
            className={classes.languageButton}
            onClick={handleLanguageMenu}
          >
            <div>
              <p>{I18N_CONFIG[language].title}</p>
            </div>
          </IconButton>
          <IconButton className={classes.menuButton} color='inherit' aria-label='Menu' onClick={handleMainMenu}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div>
        <LanguageMenu
          anchor={languageMenuAnchor}
          handleClose={() => handleLanguageMenu(null)}
          handleLanguageChanged={handleLanguageChanged}
        />
        <MainMenu open={mainMenuOpen} handleClose={handleMainMenu} classes={classes} />
      </div>
    </div>
  );
};

TopMenuComponent.propTypes = {
  handleLanguageChanged: PropTypes.func.isRequired,
};

const MainMenu = ({ open, handleClose, classes }) => {
  const { keycloak, initialized } = useKeycloak();
  const [hasCustomerView, setHasCustomerView] = useState(true);

  const handleLogout = () => {
    handleClose();
    keycloak.logout();
  };

  const intl = useIntl();
  return (
    <div>
      <SwipeableDrawer anchor='right' open={open} onClose={handleClose} onOpen={() => console.log('onOpen')}>
        <div>
          <div className={classes.menuRow}>
            <AuthorizedElement permission={PERMISSION_CUSTOMER_VIEW} isAuthorizedCallback={setHasCustomerView}>
              <AppBarMenuItemComponent
                onClick={handleClose}
                text={intl.formatMessage({ id: 'customer_list' })}
                icon={<CustomersIconComponent />}
                link={ROUTE_CUSTOMER_LIST}
              />
            </AuthorizedElement>
          </div>
          {!hasCustomerView && (
            <div className={classes.menuRow}>
              <AuthorizedElement permission={PERMISSION_DEPLOYMENT_VIEW}>
                <AppBarMenuItemComponent
                  onClick={handleClose}
                  text={intl.formatMessage({ id: 'installation_list' })}
                  icon={<InstallationsIconComponent tooltipText='see_installations' />}
                  link={ROUTE_DEPLOYMENT_LIST}
                />
              </AuthorizedElement>
            </div>
          )}
          {!!keycloak?.authenticated && (
            <div className={classes.menuRow}>
              <AppBarMenuItemComponent
                onClick={handleClose}
                text={intl.formatMessage({ id: 'user_my_profile' })}
                icon={<UserIconComponent />}
                link={ROUTE_USER_PROFILE}
              />
            </div>
          )}
          <div className={classes.menuRow}>
            <AuthorizedElement permission={PERMISSION_USER_VIEW}>
              <AppBarMenuItemComponent
                onClick={handleClose}
                text={intl.formatMessage({ id: 'view_link__management' })}
                icon={<LinkIconComponent />}
                link={ROUTE_VIEW_LINK_MANAGEMENT}
              />
            </AuthorizedElement>
          </div>
          <div className={classes.menuRow}>
            <AuthorizedElement permission={PERMISSION_USER_VIEW}>
              <AppBarMenuItemComponent
                onClick={handleClose}
                text={intl.formatMessage({ id: 'user_management' })}
                icon={<SettingsIconComponent />}
                link={ROUTE_USER_MANAGEMENT}
              />
            </AuthorizedElement>
          </div>
        </div>
        <div>
          {initialized && keycloak.authenticated ? (
            <div className={classes.menuRow}>
              <AppBarMenuItemComponent
                onClick={handleLogout}
                text={intl.formatMessage({ id: 'logout' })}
                icon={<LogoutIconComponent />}
                link='#'
              />
            </div>
          ) : (
            <div className={classes.menuRow}>
              <AppBarMenuItemComponent
                onClick={() => {
                  handleClose();
                  keycloak.login();
                }}
                text={intl.formatMessage({ id: 'login' })}
                icon={<LoginIconComponent />}
                link='#'
              />
            </div>
          )}
        </div>
      </SwipeableDrawer>
    </div>
  );
};

const LanguageMenu = ({ anchor, handleClose, handleLanguageChanged }) => {
  const intl = useIntl();

  const changeLanguage = (event, language) => {
    handleClose();
    localStorage.setItem(LOCAL_STORAGE__LOCALE, language);
    handleLanguageChanged(language);
  };

  return (
    <Menu id='language-menu' anchorEl={anchor} keepMounted open={Boolean(anchor)} onClose={handleClose}>
      <div>
        {' '}
        {/* Included as anchor for the menu */}
        {LANGUAGES_ARRAY.map((language) => (
          <AppBarMenuItemComponent
            text={intl.formatMessage({ id: I18N_CONFIG[language].label })}
            icon={<FlagIcon flag={language} />}
            onClick={(event) => changeLanguage(event, language)}
            language={language}
            key={language}
            link='#'
          />
        ))}
      </div>
    </Menu>
  );
};

LanguageMenu.propTypes = {
  anchor: PropTypes.any,
  handleClose: PropTypes.func.isRequired,
  handleLanguageChanged: PropTypes.func.isRequired,
};

function AppBarMenuItemComponent(props) {
  const { icon, language, text, link, ...rest } = props;
  const ItemLink = React.forwardRef((props, ref) => <Link ref={ref} to={link} {...props} />);

  return (
    <MenuItem button {...rest} component={ItemLink}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </MenuItem>
  );
}

AppBarMenuItemComponent.propTypes = {
  icon: PropTypes.object,
  language: PropTypes.string,
  text: PropTypes.string.isRequired,
  link: PropTypes.string,
};

export default TopMenuComponent;
