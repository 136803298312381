import React, { useImperativeHandle } from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { activateCameraService, deactivateCameraService } from '../../../../services/commonServices';

const ActivateCamera = React.forwardRef(
  ({ camera_id, cameraActive, changeCameraState, changeComponentToRender }, ref) => {
    const intl = useIntl();
    const { deployment_id } = useParams();

    useImperativeHandle(ref, () => ({
      handleOnOffCamera: () => {
        cameraActive
          ? deactivateCameraService(deployment_id, camera_id, changeCameraState)
          : activateCameraService(deployment_id, camera_id, changeCameraState);
        changeComponentToRender(null);
      },
    }));

    return (
      <div>
        <Typography>
          {cameraActive
            ? intl.formatMessage({ id: 'sure_deactivate_camera' })
            : intl.formatMessage({ id: 'sure_active_camera' })}
        </Typography>
      </div>
    );
  },
);

ActivateCamera.propTypes = {
  camera_id: PropTypes.string.isRequired,
  cameraActive: PropTypes.bool.isRequired,
  changeCameraState: PropTypes.func.isRequired,
  changeComponentToRender: PropTypes.func.isRequired,
};

export default ActivateCamera;
