import { GRAY } from '../../../utils/constants';

export const timeLineStyles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    boxShadow: '4px 4px 20px rgba(38, 37, 37, 0.1)',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  timeLabels: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {},
  },
  videoControls: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
    },
  },
  momentText: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  timeText: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'space-around',
    },
  },
  iconButton: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  active: {
    color: theme.palette.primary.main,
  },
  disabled: {
    color: GRAY,
  },
  descriptionText: {
    fontSize: '0.6rem',
    letterSpacing: '0.05rem',
  },
});
