import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { Slider, Typography, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import format from 'date-fns/format';

import PropTypes from 'prop-types';
import { timeLineStyles } from './TimeLineStyles';
import { createFullDate } from '../../../utils/utils';
import {
  setFastForwardActionCreator,
  setPlayingActionCreator,
  setRewindActionCreator,
  setSecondsActionCreator,
} from '../../../actions/commonActions';
import { useDispatch, useSelector } from 'react-redux';
import VideoControlsComponent from './components/VideoControlsComponent';
import { useIntl } from 'react-intl';
import { REWIND_CONTROL_LABEL, TIMELINE_VALUE_CHANGE } from '../../../utils/constants';

const useStyles = makeStyles((theme) => timeLineStyles(theme));

const TimelineSlider = withStyles((theme) => ({
  valueLabel: {
    left: 'calc(-50% + 6px)',
    top: '-20px',
    '& *': {
      background: 'transparent',
      color: theme.palette.primary.main,
    },
  },
  thumb: {
    height: 24,
    width: 24,
    marginTop: -8,
    marginLeft: -16,
    '&:focus, &:hover, &:active': {
      boxShadow: 'inherit',
    },
  },
  track: {
    height: 6,
  },
  rail: {
    height: 6,
  },
  mark: {
    backgroundColor: theme.palette.primary.main,
    height: 20,
    width: 6,
    marginTop: -7,
  },
}))(Slider);

function TimeLineComponent({ filters }) {
  const classes = useStyles();
  const intl = useIntl();

  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.commonState.alerts);
  const playing = useSelector((state) => state.commonState.playing);
  const seconds = useSelector((state) => state.commonState.seconds);
  const setSeconds = useCallback(
    (data) => {
      dispatch(setSecondsActionCreator({ seconds: data }));
    },
    [dispatch],
  );

  const [value, setValue] = useState(seconds);
  const [fromDate, setFromDate] = useState(createFullDate(filters.dateFrom, +filters.timeFrom * 1000));
  const [toDate, setToDate] = useState(createFullDate(filters.dateTo, +filters.timeTo * 1000));
  const [duration, setDuration] = useState(toDate - fromDate);
  const [moving, setMoving] = useState(false);
  const [marks, setMarks] = useState(null);
  const [renderSlider, setRenderSlider] = useState(0);

  const setPlaying = (data) => {
    dispatch(setPlayingActionCreator({ playing: data }));
  };

  const rewind = useSelector((state) => state.commonState.rewind);
  const setRewind = (data) => {
    dispatch(setRewindActionCreator({ rewind: data }));
  };

  const fastForward = useSelector((state) => state.commonState.fastForward);
  const setFastForward = (data) => {
    dispatch(setFastForwardActionCreator({ fastForward: data }));
  };

  const calculateAlertMark = (value) => {
    const from = createFullDate(filters.dateFrom, +filters.timeFrom * 1000);
    const alertMark = value.timestamp - +format(from, 't');
    return { value: alertMark };
  };

  const handleSetAlertMarks = (data) => {
    const marksArray = [];
    for (let alarm of data) {
      marksArray.push(calculateAlertMark(alarm));
    }
    setMarks(marksArray);
    setRenderSlider(renderSlider + 1);
  };

  useEffect(() => {
    if (alerts) {
      handleSetAlertMarks(alerts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts]);

  useEffect(() => {
    setDuration(toDate - fromDate);
  }, [toDate, fromDate]);

  useEffect(() => {
    if (!moving && seconds >= 0) {
      setValue(seconds);
    }
  }, [moving, seconds]);

  useEffect(() => {
    setFromDate(createFullDate(filters.dateFrom, +filters.timeFrom * 1000));
    setToDate(createFullDate(filters.dateTo, +filters.timeTo * 1000));
  }, [filters]);

  useEffect(() => {
    setValue(seconds);
  }, [seconds]);

  const onMoving = (newPos) => {
    setSeconds(newPos);
    setMoving(true);
    setValue(newPos);
  };

  const onMoved = (newPos) => {
    setSeconds(newPos);
    setMoving(false);
    setValue(newPos);
  };

  const handlePlaying = () => {
    setPlaying(!playing);
  };

  const handleRewind = () => {
    setRewind(!rewind);
  };

  const handleFastForward = () => {
    setFastForward(!fastForward);
  };

  const handleGoToNewPosition = (direction) => {
    const newTimeValue =
      direction === REWIND_CONTROL_LABEL ? value - TIMELINE_VALUE_CHANGE : value + TIMELINE_VALUE_CHANGE;
    setSeconds(newTimeValue);
  };

  const formatPosition = (pos) => {
    return moment.utc(pos * 1000).format('HH:mm:ss');
  };

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <>
            <Typography variant='subtitle2'>
              {intl.formatMessage({ id: 'alerts_detected' }) + marks?.length + ' '}
              <span className={classes.descriptionText}>{intl.formatMessage({ id: 'alert_description' })}</span>
            </Typography>
            <TimelineSlider
              key={renderSlider}
              valueLabelDisplay='auto'
              valueLabelFormat={formatPosition}
              getAriaValueText={formatPosition}
              min={0}
              max={duration / 1000}
              marks={marks}
              value={value}
              onChange={(event, newValue) => onMoving(newValue)}
              onChangeCommitted={(event, newValue) => onMoved(newValue)}
            />
          </>
        </Grid>
        <Grid item xs={12} container className={classes.timeLabels}>
          <Grid item md={12} lg={3} className={classes.momentText}>
            <Typography variant='subtitle2' color={'primary'}>
              {formatPosition(value)}
            </Typography>
          </Grid>
          <Grid item sm={12} md={6} className={classes.videoControls}>
            <VideoControlsComponent
              classes={classes}
              handleGoToNewPosition={handleGoToNewPosition}
              handleRewind={handleRewind}
              handlePlaying={handlePlaying}
              handleFastForward={handleFastForward}
              playing={playing}
            />
          </Grid>
          <Grid item md={12} lg={3} className={classes.timeText}>
            <Typography variant='subtitle2'>
              {intl.formatMessage({ id: 'from' })}
              {': '}
              {format(fromDate, 'HH:mm:ss')}
            </Typography>
            <Typography variant='subtitle2'>
              {intl.formatMessage({ id: 'to' })}
              {': '}
              {format(toDate, 'HH:mm:ss')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

TimeLineComponent.propTypes = {
  filters: PropTypes.object.isRequired,
};

export default TimeLineComponent;
