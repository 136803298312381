import React from 'react';

import { useKeycloak } from '@react-keycloak/web';
import { useHistory } from 'react-router-dom';

import ErrorComponent from '../errorComponent/ErrorComponent';
import EmptyComponent from '../../common/emptyComponent/EmptyComponent';
import LandingComponent from '../../landingComponent/LandingComponent';
import { ROUTE_DEPLOYMENT_LIST } from '../../../utils/routes';
import { LOADING_PERMISSIONS, ROUTE_NAME_CUSTOMER_LIST } from '../../../utils/constants';
import { useSelector } from 'react-redux';

const withAuthorization = (permission) => (Component) => {
  const WithAuthorization = (props) => {
    const permissions = useSelector((state) => state.commonState.permissions);
    const { keycloak, initialized } = useKeycloak();
    let history = useHistory();

    if (!permission) {
      return <Component {...props} />;
    }

    const isAuthorized = () => {
      if (!permission) {
        return true;
      }
      return permissions?.includes(permission);
    };

    const arePermissionsLoaded = () => {
      return permissions !== LOADING_PERMISSIONS;
    };

    if (!initialized) {
      return <EmptyComponent />;
    }

    if (!keycloak.authenticated) {
      return <LandingComponent />;
    }

    if (isAuthorized()) {
      return <Component {...props} />;
    }

    if (!arePermissionsLoaded()) {
      return <EmptyComponent />;
    }

    if (window.location.pathname.split('/')[4] === ROUTE_NAME_CUSTOMER_LIST && !isAuthorized()) {
      history.replace(ROUTE_DEPLOYMENT_LIST);
    }

    return <ErrorComponent error='error_403' />;
  };
  return WithAuthorization;
};

export default withAuthorization;
