export const styles = () => ({
  root: {
    height: 28,
    width: 28,
  },
  img: {
    height: '100%',
    width: '100%',
  },
});
