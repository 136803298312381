import { GRAY } from '../../../utils/constants';

export const styles = (theme) => ({
  footer: {
    fontSize: '0.7rem',
    letterSpacing: 1,
    zIndex: 100,
    height: 40,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    boxShadow: '0px -2px 2px 2px rgba(0, 0, 0, 0.1)',
    backgroundColor: GRAY,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
      alignContent: 'flex-end',
      boxShadow: 'none',
    },
  },
  copyright: {
    padding: theme.spacing(0, 20),
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 3),
    },
  },
  linksFooter: {
    cursor: 'pointer',
    position: 'absolute',
    right: '50px',
    top: '15px',
    color: 'gray',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      marginRight: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
      gap: '4px',
      fontSize: '0.7rem',
      textAlign: 'right',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'white',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  separation: {
    color: 'white',
    margin: '0 10px',
  },
});
