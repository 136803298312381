import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Gamepad } from 'react-gamepad';
import { getCancelToken, getCancelRequest } from '../../../../utils/axiosRequests';
import { BROWSER_FIREFOX, MAX_PENDING_REQUESTS, OS_LINUX } from '../../../../utils/constants';
import { getBrowser, getOS, transformPanTiltZoom } from '../../../../utils/utils';
import { goToPresetPtzService, panTiltPtzService, zoomPtzService } from '../../../../services/ptzService';
import { showMessage } from '../../../shared/notificationSnack/NotificationSnack';
import {
  BUTTON_MODIFIER,
  BUTTON_PRESET_DIRECT_LB,
  BUTTON_PRESET_DIRECT_RB,
  CAMERA_PRESET_MAP,
  DEFAULT_CAMERA_SPEED,
  DEFAULT_GAMEPAD_LAYOUT,
  DEFAULT_VALUE_DEAD_ZONE,
  EXTENDED_CAMERA_PRESET_MAP,
  LEFT_STICK_X,
  LEFT_STICK_Y,
  LEFT_TRIGGER,
  LINUX_FIREFOX_GAMEPAD_LAYOUT,
  PTZ_GAMEPAD,
  REQUEST_HYSTERESIS,
  REQUEST_TYPE_PAN_TILT,
  REQUEST_TYPE_ZOOM,
  RIGHT_STICK_X,
  RIGHT_STICK_Y,
  RIGHT_TRIGGER,
} from '../PtzConstants';
import { storeConnectedGamepadActionCreator } from '../../../../actions/ptzActions';
import { useDispatch } from 'react-redux';

const getButtons = () => {
  const browser = getBrowser();
  const os = getOS();

  if (browser === BROWSER_FIREFOX && os === OS_LINUX) {
    return LINUX_FIREFOX_GAMEPAD_LAYOUT;
  }
  return DEFAULT_GAMEPAD_LAYOUT;
};

const GamepadComponent = ({ cameraId }) => {
  const dispatch = useDispatch();
  const [windowFocus, setWindowFocus] = useState(true);

  let pan = 0;
  let tilt = 0;
  let zoom = 0;
  let modifier = false;
  let currentRequests = [];
  let lastRequestTimestamp = new Date().getTime();
  const buttons = getButtons();
  const layout = {
    buttons,
    axis: [LEFT_STICK_X, `-${LEFT_STICK_Y}`, RIGHT_STICK_X, `-${RIGHT_STICK_Y}`],
    buttonAxis: [null, null, null, null, null, null, LEFT_TRIGGER, RIGHT_TRIGGER],
  };

  const storeConnectedGamepad = (value) => {
    dispatch(storeConnectedGamepadActionCreator(value));
  };

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  const onFocus = () => {
    setWindowFocus(true);
  };

  const onBlur = () => {
    setWindowFocus(false);
  };

  const connectHandler = () => {
    storeConnectedGamepad(true);
    console.debug('Gamepad connected.');
  };

  const disconnectHandler = () => {
    storeConnectedGamepad(false);
    console.debug('Gamepad disconnected.');
  };

  const isPreset = (buttonName, modifier) => {
    let preset = undefined;
    if (!!windowFocus) {
      if (modifier || buttonName === BUTTON_PRESET_DIRECT_RB || buttonName === BUTTON_PRESET_DIRECT_LB) {
        preset = CAMERA_PRESET_MAP[buttonName];
      }
      if (!preset) {
        preset = EXTENDED_CAMERA_PRESET_MAP[buttonName];
      }
    }
    return preset;
  };

  const buttonChangeHandler = (buttonName, down) => {
    console.debug('Button: ' + buttonName);
    if (windowFocus && buttonName === BUTTON_MODIFIER) {
      modifier = down;
    }
    if (windowFocus && down === false) {
      return;
    }

    let preset = isPreset(buttonName, modifier);
    if (!!preset) {
      const position = preset < 10 ? `0${preset}` : preset.toString();

      const data = {
        preset_id: position,
      };
      goToPresetPtzService(cameraId, data, onSuccess, onError);
    }
  };

  const axisChangeHandler = (axisName, value, previousValue) => {
    if (!!windowFocus && (!cameraId || value === previousValue)) {
      return;
    }
    let doPanTilt = false,
      doZoom = false;
    let parameters = undefined,
      type = undefined;

    if (!!windowFocus) {
      switch (axisName) {
        case PTZ_GAMEPAD.map.pan: {
          pan = value;
          doPanTilt = true;
          type = REQUEST_TYPE_PAN_TILT;
          parameters = {
            pan: transformPanTiltZoom(pan, DEFAULT_CAMERA_SPEED),
            tilt: transformPanTiltZoom(tilt, DEFAULT_CAMERA_SPEED),
          };
          break;
        }
        case PTZ_GAMEPAD.map.tilt: {
          tilt = value;
          doPanTilt = true;
          type = REQUEST_TYPE_PAN_TILT;
          parameters = {
            pan: transformPanTiltZoom(pan, DEFAULT_CAMERA_SPEED),
            tilt: transformPanTiltZoom(tilt, DEFAULT_CAMERA_SPEED),
          };
          break;
        }
        case PTZ_GAMEPAD.map.zoom: {
          zoom = value;
          doZoom = true;
          type = REQUEST_TYPE_ZOOM;
          parameters = {
            zoom: transformPanTiltZoom(zoom, DEFAULT_CAMERA_SPEED),
          };
          break;
        }
        default:
          break;
      }
    }

    if (!!windowFocus && !doPanTilt && !doZoom) {
      return;
    }
    if (!!windowFocus && (!availableRequests || value < DEFAULT_VALUE_DEAD_ZONE)) {
      cancelRequests();
    }

    !!windowFocus && addRequest(type, parameters);
  };

  const addRequest = (type, parameters) => {
    console.debug('REQUESTS: ', currentRequests.length);
    const timestamp = new Date().getTime();

    let cancelable = true;
    let call = () => void 0;

    if (type === REQUEST_TYPE_PAN_TILT) {
      call = () => panTiltPtzService(cameraId, parameters, onSuccess, onError);
      cancelable = !(
        Math.abs(parameters.pan) < DEFAULT_VALUE_DEAD_ZONE && Math.abs(parameters.tilt) < DEFAULT_VALUE_DEAD_ZONE
      );
    } else if (type === REQUEST_TYPE_ZOOM) {
      call = () => zoomPtzService(cameraId, parameters, onSuccess, onError);
      cancelable = Math.abs(parameters.zoom) > DEFAULT_VALUE_DEAD_ZONE;
    }

    console.debug('**** control: ', timestamp - lastRequestTimestamp, '(', parameters.pan, ', ', parameters.tilt, ')');

    if (cancelable && timestamp - lastRequestTimestamp < REQUEST_HYSTERESIS) {
      console.debug(
        '**** discarded: ',
        timestamp - lastRequestTimestamp,
        '(',
        parameters.pan,
        ', ',
        parameters.tilt,
        ')',
      );
      return;
    }

    lastRequestTimestamp = timestamp;
    const CancelToken = getCancelToken();
    const source = CancelToken.source();

    call();

    currentRequests.push({
      type,
      source,
      timestamp,
      cancelable,
    });
  };

  const removeRequest = (timestamp) => {
    currentRequests = currentRequests.filter((request) => timestamp !== request.timestamp);
  };

  const onSuccess = (response) => {
    removeRequest(lastRequestTimestamp);
    console.debug('Request successful', response);
  };

  const onError = (error) => {
    if (getCancelRequest(error)) {
      console.warn('Request canceled', error.message);
    } else {
      showMessage(error);
    }
  };

  const availableRequests = () => currentRequests.length < MAX_PENDING_REQUESTS;

  const cancelRequests = (type) => {
    currentRequests = currentRequests.reduce((acc, request) => {
      if (!type || request.type === type) {
        request.source.cancel('Too many PTZ requests.');
      } else {
        acc.push(request);
      }
      return acc;
    }, []);
  };

  return (
    <Gamepad
      onConnect={connectHandler}
      onDisconnect={disconnectHandler}
      onButtonChange={buttonChangeHandler}
      onAxisChange={axisChangeHandler}
      layout={layout}
    >
      <React.Fragment />
    </Gamepad>
  );
};

GamepadComponent.propTypes = {
  cameraId: PropTypes.string.isRequired,
};

export default GamepadComponent;
