import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getContractsList, getStreamSourcesByDeployment, getUniqueMosaic } from '../../../services/commonServices';
import CameraMosaicComponent from './components/cameraMosaicComponent/CameraMosaicComponent';
import {
  fetchDeploymentCameras,
  filterCameraMosaicActionCreator,
  putCameraMosaicActionCreator,
  setCustomerCameraListAction,
} from '../../../actions/commonActions';
import { useKeycloak } from '@react-keycloak/web';
import { buildUrl } from '../../../utils/axiosRequests';
import { LOCAL_STORAGE__FILTERED_CAMERA_LIST, TIIVII_API_DEPLOYMENT_LIST_BY_CUSTOMER } from '../../../utils/constants';

const CameraMosaicContainer = () => {
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();
  const { deployment_id, mosaic_id, customer_id } = useParams();

  const [mosaic, setMosaic] = useState(null);
  const [groupMosaicCamerasToShow, setGroupMosaicCamerasToShow] = useState(0);
  const storedCameraList = JSON.parse(localStorage.getItem(deployment_id));
  const cameraList = useSelector((state) => state.commonState.cameraList[deployment_id]);
  const filteredCameraList = useSelector((state) => state.commonState.filteredCameraList);
  const storedFilteredCameraList = JSON.parse(localStorage.getItem(LOCAL_STORAGE__FILTERED_CAMERA_LIST));
  const allCameras = useSelector((state) => state.commonState.cameraList);

  const [camerasToShow, setCamerasToShow] = useState(null);
  const [cameraGroupToShow, setCameraGroupToShow] = useState(null);

  useEffect(() => {
    if (mosaic_id && mosaic) {
      setCameraGroupToShow(
        mosaic.mosaic_config.camera_lists.map((group) =>
          group.map((cameraId) => mosaic.camera_details.find((camera) => camera._id === cameraId)),
        ),
      );
    } else if (!mosaic_id && cameraList) {
      setCameraGroupToShow(cameraList);
    }
  }, [mosaic, cameraList, mosaic_id]);

  const filterCameraMosaic = useCallback(
    (cameras) => {
      dispatch(filterCameraMosaicActionCreator({ deployment_id, filteredCameraList: cameras }));
      localStorage.setItem(
        LOCAL_STORAGE__FILTERED_CAMERA_LIST,
        JSON.stringify({ deployment_id, filteredCameraList: cameras }),
      );
    },
    [dispatch, deployment_id],
  );

  const successContractList = useCallback(
    async (response) => {
      if (!response.data.length) return;

      const promises = [];

      for (const deployment of response.data) {
        promises.push(getStreamSourcesByDeployment(deployment.deployment_id));
      }

      Promise.allSettled(promises).then((results) => {
        let cameras = [];
        for (const result of results) {
          if (result.status === 'fulfilled') {
            cameras.push(result.value.data);
          }
        }

        dispatch(setCustomerCameraListAction(cameras.flat(1)));
        setCamerasToShow(cameras.flat(1));
      });
    },
    [dispatch],
  );

  const loadCustomerCameras = useCallback(() => {
    const cameras = [];

    if (!!Object.keys(allCameras).length) {
      for (const deploymentId of Object.keys(allCameras)) {
        cameras.push(allCameras[deploymentId]);
      }

      setCamerasToShow(cameras.flat(1));
    } else {
      const url = buildUrl(TIIVII_API_DEPLOYMENT_LIST_BY_CUSTOMER, {
        customer_id: customer_id,
      });

      getContractsList(url, successContractList);
    }
  }, [allCameras, customer_id, successContractList]);

  useEffect(() => {
    if (keycloak && initialized) {
      if (deployment_id) {
        void dispatch(fetchDeploymentCameras(deployment_id));
      } else if (mosaic_id && mosaic) {
        for (const deployment of mosaic.deployment_ids) {
          dispatch(
            putCameraMosaicActionCreator({
              deployment_id: deployment,
              cameraList: mosaic.camera_details.filter((camera) => camera.deployment_id === deployment),
            }),
          );
        }
      } else if (customer_id) {
        loadCustomerCameras();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak, initialized, mosaic, deployment_id, dispatch, mosaic_id, customer_id]);

  useEffect(() => {
    if (filteredCameraList[deployment_id]?.length > cameraList?.length) {
      filterCameraMosaic(cameraList);
      localStorage.setItem(deployment_id, JSON.stringify(cameraList));
    } else if (!filteredCameraList[deployment_id] && !!storedCameraList?.length) {
      filterCameraMosaic(storedCameraList);
    } else if (
      !filteredCameraList[deployment_id] &&
      !storedCameraList?.length &&
      cameraList &&
      !storedFilteredCameraList
    ) {
      filterCameraMosaic(cameraList);
    } else {
      filterCameraMosaic(storedFilteredCameraList?.filteredCameraList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraList]);

  const handleSetMosaic = (response) => {
    setMosaic(response.data);
  };

  useEffect(() => {
    if (mosaic_id && keycloak?.token) {
      getUniqueMosaic(mosaic_id, handleSetMosaic);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mosaic_id, keycloak.token]);

  useEffect(() => {
    let secondsInterval = null;
    if (mosaic && mosaic.mosaic_config.camera_lists.length > 1) {
      secondsInterval = setInterval(() => {
        groupMosaicCamerasToShow === mosaic.mosaic_config.camera_lists.length - 1
          ? setGroupMosaicCamerasToShow(0)
          : setGroupMosaicCamerasToShow((groupMosaicCamerasToShow) => groupMosaicCamerasToShow + 1);
      }, mosaic?.mosaic_config?.time);
    }
    return () => clearInterval(secondsInterval);
  }, [mosaic, groupMosaicCamerasToShow]);

  useEffect(() => {
    if (mosaic && mosaic_id && cameraGroupToShow) {
      setCamerasToShow(cameraGroupToShow[groupMosaicCamerasToShow]);
    }
  }, [groupMosaicCamerasToShow, mosaic, cameraGroupToShow, mosaic_id]);

  useEffect(() => {
    if (!mosaic_id && cameraList) {
      setCamerasToShow(cameraList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraList]);

  return (
    <div>
      {!!camerasToShow?.length && (
        <CameraMosaicComponent
          mosaicId={mosaic_id}
          deploymentId={deployment_id}
          customerId={customer_id}
          camerasToShow={camerasToShow}
        />
      )}
    </div>
  );
};
export default CameraMosaicContainer;
