import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import LoadingComponent from '../../shared/loading/LoadingComponent';
import { GRAY, MIN_HEIGHT } from '../../../utils/constants';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  title: {
    marginTop: theme.spacing(36),
    color: GRAY,
  },
});
const useStyles = makeStyles((theme) => styles(theme));

const EmptyComponent = ({ noData, title }) => {
  const classes = useStyles();
  const commonStateLoading = useSelector((state) => state.commonState.loading);

  return (
    <div style={{ minHeight: MIN_HEIGHT }}>
      <LoadingComponent open={commonStateLoading ? false : !noData} description />
      {noData && (
        <h1 className={classes.title}>
          <FormattedMessage id={title} />
        </h1>
      )}
    </div>
  );
};

EmptyComponent.propTypes = {
  noData: PropTypes.bool,
  title: PropTypes.string,
};

export default EmptyComponent;
