import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import keycloak, { keycloakInitConfig } from './utils/keycloak';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { APP_VERSION } from './utils/constants';
import store from './store';

global.app_version = APP_VERSION;

const renderApp = () => {
  render(
    <ReactKeycloakProvider authClient={keycloak} initOptions={keycloakInitConfig}>
      <Provider store={store}>
        <App />
      </Provider>
    </ReactKeycloakProvider>,
    document.getElementById('root'),
  );
};

renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
