import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import AbstractTable from '../../shared/abstractTable/AbstractTable';
import { GRAY } from '../../../utils/constants';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { formatDate, getCurrentTenant } from '../../../utils/utils';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { showMessage } from '../../shared/notificationSnack/NotificationSnack';
import { APP_BASE_ROUTE } from '../../../utils/routes';

const ViewLinkTableComponent = ({ userList, classes, handleExpirateViewLinkConfirmation }) => {
  const intl = useIntl();

  const VIEW_LINK__EXPIRATION_DATE = intl.formatMessage({
    id: 'view_link__expiration_date',
  });
  const VIEW_LINK__DESCRIPTION = intl.formatMessage({
    id: 'view_link__description',
  });
  const VIEW_LINK__VIEWS = intl.formatMessage({ id: 'view_link__views' });
  const VIEW_LINK__LINK = intl.formatMessage({ id: 'view_link__link' });
  const VIEW_LINK__ACTIONS = intl.formatMessage({ id: 'view_link__actions' });

  const renderUrl = (item) => {
    return `${window.location.origin}${APP_BASE_ROUTE.replace(':tenant', getCurrentTenant())}/viewer/${item._id}`;
  };

  const truncateText = (text) => (
    <div
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 300,
      }}
    >
      {text}
    </div>
  );

  return (
    <AbstractTable
      columns={[
        {
          title: VIEW_LINK__EXPIRATION_DATE,
          field: 'expiration_date',
          headerStyle: {
            width: 120,
          },
          cellStyle: {
            width: 120,
          },
          render: (rowData) => formatDate(rowData.expiration_date * 1000),
        },
        {
          title: VIEW_LINK__DESCRIPTION,
          field: 'description',
          headerStyle: {
            width: '30%',
          },
          cellStyle: {
            width: '30%',
          },
          render: (rowData) => truncateText(rowData.description),
        },
        {
          title: VIEW_LINK__VIEWS,
          field: 'visits',
          headerStyle: {
            width: '5%',
          },
          cellStyle: {
            width: '5%',
          },
        },
        {
          title: VIEW_LINK__LINK,
          field: 'link',
          headerStyle: {
            width: '30%',
          },
          cellStyle: {
            width: '30%',
          },
          render: (rowData) => (
            <div className={classes.rowDataLink}>
              <a
                href={renderUrl(rowData)}
                target='_blank'
                rel='noopener noreferrer'
                className={classes.rowDataLinkText}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: 300,
                }}
              >
                {renderUrl(rowData)}
              </a>
              <IconButton
                onClick={() => {
                  showMessage(intl.formatMessage({ id: 'view_link_modal__link_copied' }), 'success');
                  navigator.clipboard.writeText(renderUrl(rowData));
                }}
              >
                <FileCopyIcon fontSize='small' />
              </IconButton>
            </div>
          ),
        },
        {
          title: VIEW_LINK__ACTIONS,
          render: (rowData) => (
            <div className={classes.tableButtonContainer}>
              <Tooltip title={intl.formatMessage({ id: 'view_link__expirate' })}>
                <IconButton className={classes.iconButton} onClick={() => handleExpirateViewLinkConfirmation(rowData)}>
                  <DeleteForeverRoundedIcon />
                </IconButton>
              </Tooltip>
            </div>
          ),
          headerStyle: {
            width: '10%',
            textAlign: 'center',
          },
          cellStyle: {
            width: '10%',
            textAlign: 'center',
          },
        },
      ]}
      data={userList || []}
      options={{
        actionsColumnIndex: -1,
        showEmptyDataSourceMessage: true,
        doubleHorizontalScroll: false,
        pageSize: 5,
        pageSizeOptions: [5, 10, 25, 50],
        search: false,
        toolbar: false,
        headerStyle: {
          backgroundColor: GRAY,
          color: 'white',
          fontWeight: 'bold',
        },
        tableLayout: 'auto',
      }}
      style={{ boxShadow: '4px 4px 20px rgba(38, 37, 37, 0.1)' }}
    />
  );
};

ViewLinkTableComponent.propTypes = {
  contractList: PropTypes.array,
};

export default ViewLinkTableComponent;
