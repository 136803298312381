import { Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

export default function BreadCrumbsComponent({ breadCrumbs, classes }) {
  return (
    <Typography variant='subtitle2' className={classes.subtitle}>
      {breadCrumbs}
    </Typography>
  );
}

BreadCrumbsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  breadCrumbs: PropTypes.object,
};
