import React, { useEffect, useState, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { updateUserPassword } from '../../../services/commonServices';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './AdminPasswordStyles';
import { MESSAGE_TIMEOUT, MIN_HEIGHT } from '../../../utils/constants';

const useStyles = makeStyles((theme) => styles(theme, { MIN_HEIGHT }));

const AdminPasswordComponent = ({ username, partnerId, handlePasswordModal }) => {
  const intl = useIntl();
  const classes = useStyles();

  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');

  const [mustMatchWarning, setMustMatchWarning] = useState(false);

  const [successMessage, setSuccessMessage] = useState(false);
  const [failureMessage, setFailureMessage] = useState(false);

  const resetInputValues = () => {
    handleNewPassword1('');
    handleNewPassword2('');
  };

  useEffect(() => {
    resetInputValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetSuccessMessage = (value) => {
    setSuccessMessage(value);
  };

  const handleSetFailureMessage = (value) => {
    setFailureMessage(value);
  };

  const handleUpdateSuccess = () => {
    handleSetSuccessMessage(true);

    setTimeout(() => {
      handleSetSuccessMessage(false);
      resetInputValues();
      handlePasswordModal();
    }, MESSAGE_TIMEOUT);
  };

  const handleUpdateFailure = () => {
    handleSetFailureMessage(true);

    setTimeout(() => {
      handleSetFailureMessage(false);
    }, MESSAGE_TIMEOUT);
  };

  const handleUpdateUserCredentials = () => {
    const body = {
      credentials: {
        username,
        password: newPassword1,
      },
      owned_by_partner_id: partnerId,
    };

    updateUserPassword(username, body, handleUpdateSuccess, handleUpdateFailure);
  };

  const handleNewPassword1 = (value) => {
    setNewPassword1(value);
  };

  const handleNewPassword2 = (value) => {
    setNewPassword2(value);
  };

  const handleMustMatchWarning = useCallback((value) => {
    setMustMatchWarning(value);
  }, []);

  useEffect(() => {
    if (newPassword1 && newPassword2 && newPassword1 !== newPassword2 && !mustMatchWarning) {
      handleMustMatchWarning(true);
    } else if (newPassword1 && newPassword2 && newPassword1 === newPassword2 && mustMatchWarning) {
      handleMustMatchWarning(false);
    }

    if (!newPassword1 || !newPassword2) {
      handleMustMatchWarning(false);
    }
  }, [newPassword1, newPassword2, handleMustMatchWarning, mustMatchWarning]);

  const validatePassword = () => {
    return !!newPassword1 && !!newPassword2 && !mustMatchWarning;
  };

  return (
    <>
      <form className={classes.textFieldContainer}>
        <div className={classes.textFieldBlock}>
          <TextField
            id='new1-standard-basic'
            type='password'
            value={newPassword1}
            label={intl.formatMessage({ id: 'user_password_new' })}
            className={classes.textField}
            onChange={(e) => handleNewPassword1(e.target.value)}
          />
          <TextField
            id='new2-standard-basic'
            type='password'
            value={newPassword2}
            label={intl.formatMessage({ id: 'user_password_new_again' })}
            className={classes.textField}
            onChange={(e) => handleNewPassword2(e.target.value)}
          />
        </div>
      </form>
      <div className={classes.buttonContainer}>
        <Button
          onClick={handlePasswordModal}
          className={classes.secondaryButton}
          classes={{ disabled: classes.disabled }}
        >
          {intl.formatMessage({ id: 'close' })}
        </Button>
        <Button
          onClick={handleUpdateUserCredentials}
          disabled={!validatePassword()}
          className={classes.primaryButton}
          classes={{ disabled: classes.disabled }}
        >
          {intl.formatMessage({ id: 'global_buttons_send' })}
        </Button>
      </div>
      <div className={classes.formFooter}>
        <div className={classes.warningContainer}>
          {mustMatchWarning && (
            <Typography variant='subtitle2' color='secondary'>
              {intl.formatMessage({ id: 'user_password_warning_match' })}
            </Typography>
          )}
        </div>
      </div>
      <div className={classes.messageContainer}>
        {successMessage && (
          <Typography variant='subtitle2' color='secondary'>
            {intl.formatMessage({ id: 'user_password_edit_success' })}
          </Typography>
        )}
        {failureMessage && (
          <Typography variant='subtitle2' color='secondary'>
            {intl.formatMessage({ id: 'user_password_edit_failure' })}
          </Typography>
        )}
      </div>
    </>
  );
};

export default AdminPasswordComponent;
