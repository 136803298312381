import { convertHexToFilter } from '../../../utils/colorUtils';

export const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '0px',
    backgroundColor: 'rgba(0,0,0,0.8)',
    zIndex: 99999,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  disable: {
    display: 'none',
  },
  image: {
    animation: 'rotation 3s infinite linear',
  },
  description: {
    color: 'white',
    marginTop: 34,
    fontSize: 24,
  },
  spinner: {
    filter: convertHexToFilter(theme.palette.primary.main),
  },
});
