import React, { useEffect, useRef, useState } from 'react';
import { Card, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import poster from '../../../images/loadingV2.gif';
import { VIDEO_RATIO_PERCENTAGE } from '../../../utils/constants';
import { styles } from './TemporaryCameraCardStyles';
import { useSelector } from 'react-redux';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import TemporaryVideoComponent from '../../common/temporaryVideoComponent/TemporaryVideoComponent';

const TemporaryCameraCardComponent = ({ camera, gridSize, camerasList, detail }) => {
  const deployment = camera.deployment_id;
  const element = useRef(null);
  const intl = useIntl();

  const [videoSize, setVideoSize] = useState(null);

  const cameraList = useSelector((state) => state.commonState.cameraList[deployment]);

  const useStyles = makeStyles((theme) => styles(theme, cameraList && cameraList.length, videoSize));
  const classes = useStyles();

  const handle = useFullScreenHandle();

  const handleResize = () => {
    const width = element.current.clientWidth;
    setVideoSize(width * VIDEO_RATIO_PERCENTAGE);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  useEffect(() => {
    if (
      element.current.clientWidth !== element.current.clientHeight &&
      element.current.clientWidth > element.current.clientHeight
    ) {
      handleResize();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element.current]);

  useEffect(() => {
    handleResize();
  }, [gridSize, camerasList]);

  return (
    <div className={classes.cardContent} ref={element}>
      <Card className={classes.cardHeader}>
        <div className={classes.cameraStatus}>
          <div>
            <Tooltip title={intl.formatMessage({ id: 'mosaic' })} className={classes.tooltip}>
              <IconButton onClick={handle.enter} classes={{ root: classes.iconButton }}>
                <FullscreenIcon className={classes.iconMainColor} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Card>
      <Card elevation={0} className={classes.videoCardMosaic}>
        <FullScreen handle={handle} className={classes.fullScreenComponent}>
          <TemporaryVideoComponent
            poster={poster}
            live={true}
            camera={camera}
            detail={detail}
            videoSize={videoSize}
            fullScreen={handle.active}
          />
        </FullScreen>
      </Card>
    </div>
  );
};

TemporaryCameraCardComponent.propTypes = {
  camera: PropTypes.object.isRequired,
  detail: PropTypes.bool,
};

export default TemporaryCameraCardComponent;
