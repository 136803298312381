import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { GRAY } from '../../../../../utils/constants';
import { patchFilteredMosaic, postFilteredMosaic } from '../../../../../services/commonServices';
import { tenantPrimaryButton, tenantSecondaryButton } from '../../../../../utils/tenantStyles';
import MosaicTabsComponent from './components/mosaicTabsComponent/MosaicTabsComponent';

const useStyles = makeStyles((theme) => ({
  modal: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
  },
  modalFiltersFlex: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
    marginBottom: theme.spacing(4),
  },
  modalFilters: {
    width: '100%',
    marginLeft: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    boxSizing: 'border-box',
    height: '3rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    color: 'white',
    backgroundColor: GRAY,
    padding: '1rem',
  },
  delete: {
    border: '1px solid white',
    color: GRAY,
    '&:hover': {
      backgroundColor: 'white !important',
      borderColor: GRAY + '!important',
      color: GRAY + '!important',
    },
  },
  save: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(6),
  },
  verticalTabs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  verticalTabsDisabled: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
  },
  description: {
    minWidth: 300,
  },
  mosaicTextField: {
    color: GRAY + ' !important',
  },
  primaryButton: tenantPrimaryButton(theme),
  secondaryButton: tenantSecondaryButton(theme),
  disabled: {
    backgroundColor: theme.palette.disabled.light + '!important',
    border: 0 + '!important',
    color: theme.palette.disabled.main + '!important',
  },
  iconButton: {
    '&:hover': {
      color: theme.palette.primary.main + ' !important',
    },
  },
}));

function MosaicFormComponent({
  mosaic,
  deploymentList,
  handleCloseMosaicForm,
  savedFiltersMosaic,
  handleSetMosaicSelected,
  customerCameras,
  newMosaic,
  getMosaics,
  handleSetMosaicList,
}) {
  const classes = useStyles();
  const intl = useIntl();

  const [groupIndex, setGroupIndex] = useState(0);

  const [name, setName] = useState('');
  const [time, setTime] = useState(null);
  const [description, setDescription] = useState('');
  const [hasChanges, setHasChanges] = useState(false);

  const [groups, setGroups] = useState(null);

  useEffect(() => {
    handleSetGroupIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mosaic]);

  useEffect(() => {
    setHasChanges(false);
    if (mosaic) {
      handleSetGroups(mosaic.mosaic_config.camera_lists);
      setName(mosaic.mosaic_name);
      setDescription(mosaic.mosaic_description);
      setTime(mosaic.mosaic_config.time / 1000);
    } else {
      const newMosaic = {
        deployment_ids: deploymentList,
        mosaic_name: '',
        mosaic_description: '',
        mosaic_config: {
          time: null,
          camera_lists: [[]],
        },
      };
      handleSetMosaicSelected(newMosaic);
    }
  }, [mosaic, deploymentList, handleSetMosaicSelected]);

  const handleSetGroups = (value) => {
    setGroups(value);
  };

  const handleSetGroupIndex = (value) => {
    setGroupIndex(value);
  };

  const saveNewFilter = () => {
    const trimmedGroups = groups.filter((group) => group.length);

    const body = newMosaic
      ? {
          deployment_ids: deploymentList,
          mosaic_name: name,
          mosaic_description: description,
          mosaic_config: {
            time: time * 1000,
            camera_lists: trimmedGroups,
          },
        }
      : {
          mosaic_name: name,
          mosaic_description: description,
          time: time * 1000,
        };

    newMosaic ? postFilteredMosaic(body, handleFilterSave) : patchFilteredMosaic(mosaic._id, body, handleFilterSave);
  };

  const handleFilterSave = (response) => {
    if (response.status === 204 || response.status === 200) {
      handleCloseMosaicForm();
      getMosaics(handleSetMosaicList);
    }
  };

  const handleDisabledButton = () => {
    return newMosaic
      ? !name || !description || (groups.length > 1 && !(time > 0)) || !groups[0].length > 0
      : !name || !description || (groups?.length > 1 && !(time > 0)) || !hasChanges;
  };

  return (
    <div className={classes.modal}>
      <div className={classes.modalFilters}>
        <form className={classes.modalFiltersFlex} noValidate autoComplete='off'>
          <TextField
            required
            onChange={(e) => {
              setName(e.target.value);
              setHasChanges(true);
            }}
            label={intl.formatMessage({ id: 'filters_name' })}
            value={name}
            className={classes.mosaicTextField}
          />
          <TextField
            required
            onChange={(e) => {
              setDescription(e.target.value);
              setHasChanges(true);
            }}
            label={intl.formatMessage({ id: 'filters_description' })}
            value={description}
            className={`${classes.mosaicTextField} ${classes.description}`}
          />
          <TextField
            disabled={groups?.length <= 1}
            required={groups?.length > 1}
            onChange={(e) => {
              setTime(e.target.value);
              setHasChanges(true);
            }}
            type={'number'}
            label={intl.formatMessage({ id: 'filters_time' })}
            value={+time}
            className={classes.mosaicTextField}
          />
        </form>
      </div>
      <div className={classes.verticalTabs}>
        {newMosaic && (
          <MosaicTabsComponent
            savedFiltersMosaic={savedFiltersMosaic}
            customerCameras={customerCameras}
            handleSetGroups={handleSetGroups}
            groups={groups}
            groupIndex={groupIndex}
            handleSetGroupIndex={handleSetGroupIndex}
            newMosaic={newMosaic}
          />
        )}
      </div>
      <div className={classes.save}>
        <Button onClick={handleCloseMosaicForm} className={classes.secondaryButton}>
          {intl.formatMessage({ id: 'close' })}
        </Button>
        <Button
          onClick={saveNewFilter}
          className={!!handleDisabledButton() ? classes.disabled : classes.primaryButton}
          disabled={!!handleDisabledButton()}
        >
          {intl.formatMessage({
            id: newMosaic ? 'filter_button_create' : 'filter_button_save',
          })}
        </Button>
      </div>
    </div>
  );
}

export default MosaicFormComponent;
