import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { isString } from '../../../utils/utils';
import { MIN_HEIGHT } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  main: {
    color: '#888',
    margin: 0,
    marginTop: 24,
    display: 'table',
    width: '100%',
    minHeight: MIN_HEIGHT,
    textAlign: 'center',
  },
  fof: {
    display: 'table-cell',
    verticalAlign: 'middle',
    '& h1': {
      fontSize: '50px',
      display: 'inline-block',
      padding: theme.spacing(1.5),
      animation: '$type .5s alternate infinite',
      [theme.breakpoints.down('md')]: {
        fontSize: '25px',
      },
    },
  },
  '@keyframes type': {
    from: {
      boxShadow: 'inset -3px 0px 0px #888',
    },
    to: {
      boxShadow: 'inset -3px 0px 0px transparent',
    },
  },
}));

const ErrorComponent = ({ error }) => {
  const classes = useStyles();
  const intl = useIntl();
  const message = isString(error) ? intl.formatMessage({ id: error }) : error;

  return (
    <div className={classes.main}>
      <div className={classes.fof}>
        <h1>{message}</h1>
      </div>
    </div>
  );
};

ErrorComponent.propTypes = {
  /**
   * string containing a react-intl key or any component.
   */
  error: PropTypes.any.isRequired,
};

export default ErrorComponent;
