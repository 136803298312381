import React from 'react';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { goToPresetPtzService } from '../../../services/ptzService';
import { onError, onSuccess } from '../../../utils/utils';
import { PRESETS_CONTROLS } from '../ptz/PtzConstants';

const useStyles = makeStyles((theme) => ({
  itemButton: {
    padding: '0 !important',
    minWidth: 120,
    maxWidth: 200,
    cursor: 'pointer',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  presetKey: {
    border: `2px solid ${theme.palette.primary.main} !important`,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    width: '50%',
    padding: theme.spacing(1, 2),
  },
  presetAction: {
    border: `2px solid ${theme.palette.primary.main} !important`,
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    padding: theme.spacing(1, 2),
  },
  presetButtonContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    width: '100%',
    flexFlow: 'row wrap',
  },
}));

export default function CameraPresetsComponent({ cameraId, keyboardDevice }) {
  const classes = useStyles();
  const presetList = useSelector((state) => state.ptzState.presets);
  const home = useSelector((state) => state.ptzState.home);

  const goTo = (preset) => {
    const position = preset < 10 ? `0${preset}` : preset.toString();
    const data = {
      preset_id: position,
    };
    goToPresetPtzService(cameraId, data, onSuccess, onError);
  };

  return (
    <div>
      <Grid container className={classes.presetButtonContainer}>
        {presetList?.map((preset, index) => (
          <Grid
            item
            xs={12}
            md={4}
            key={`preset-${index}`}
            onClick={() => goTo(preset.index)}
            className={classes.itemButton}
          >
            <div className={classes.presetKey}>
              {!!preset.button
                ? keyboardDevice
                  ? PRESETS_CONTROLS.find((p) => p[1] === preset.button)[0]
                  : PRESETS_CONTROLS.find((p) => p[1] === preset.button)[1]
                : null}
            </div>
            <div className={classes.presetAction}>
              {preset.name}
              {home && preset.index === home.index ? '*' : ''}
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

CameraPresetsComponent.propTypes = {
  cameraId: PropTypes.string.isRequired,
  keyboardDevice: PropTypes.bool.isRequired,
};
