export const APP_BASE_ROUTE = `/${process.env.REACT_APP_BASE_ROUTE}/:tenant`;

export const ROUTE_LANDING = '/';
export const ROUTE_DEPLOYMENT_LIST = '/deployments';
export const ROUTE_DEPLOYMENT_LIST_CHECK = '/deployments-check';
export const ROUTE_DEPLOYMENT_LIST_COMPLETE = `${APP_BASE_ROUTE}/deployments`;
export const ROUTE_CUSTOMER_LIST = '/customers';
export const ROUTE_CUSTOMER_LIST_CHECK = '/customers-check';
export const ROUTE_CUSTOMER_LIST_COMPLETE = `${APP_BASE_ROUTE}/customers`;
export const ROUTE_CUSTOMER = '/customers/:customer_id';
export const ROUTE_CUSTOMER_MOSAIC = '/customers/:customer_id/mosaic';
export const ROUTE_CUSTOMER_COMPLETE = `${APP_BASE_ROUTE}/customers/:customer_id`;

export const ROUTE_DEPLOYMENT = '/deployments/:deployment_id';
export const ROUTE_DEPLOYMENT_COMPLETE = `${APP_BASE_ROUTE}/deployments/:deployment_id`;
export const ROUTE_DEPLOYMENT_MOSAIC = '/deployments/:deployment_id/mosaic';
export const ROUTE_DEPLOYMENT_MOSAIC_COMPLETE = `${APP_BASE_ROUTE}/deployments/:deployment_id/mosaic`;
export const ROUTE_CAMERA = '/deployments/:deployment_id/camera/:camera_id';
export const ROUTE_CAMERA_COMPLETE = `${APP_BASE_ROUTE}/deployments/:deployment_id/camera/:camera_id`;
export const ROUTE_USER_PROFILE = '/profile';
export const ROUTE_USER_MANAGEMENT = '/user-management';
export const ROUTE_VIEW_LINK_MANAGEMENT = '/view-link-management';
export const ROUTE_DEPLOYMENT_GROUP_MOSAIC = '/mosaic/:mosaic_id';
export const ROUTE_DEPLOYMENT_GROUP_MOSAIC_COMPLETE = `${APP_BASE_ROUTE}/mosaic/:mosaic_id`;
export const ROUTE_TEMPORARY_VIEWER = '/viewer/:viewer_id';
export const ROUTE_TEMPORARY_VIEWER_COMPLETE = `${APP_BASE_ROUTE}/viewer/:viewer_id`;
